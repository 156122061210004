<template>
  <v-container class="mt-5 ml-2" fluid>
    <v-row>
      <v-col cols="12">
      	
      	<v-card class="shadowCard">
      	  <v-card-title class="text-subtitle-1">
      	    Rol de teachers
      	    <v-spacer></v-spacer>

      	    <botonesRoles
      	    	v-if="![1552,1127,1336,1177,886,1593].includes( this.getdatosUsuario.iderp )"
      	    	@abrirComparativa="abrirComparativa"
      	    	@exportarRol="exportarRol3"
      	    	@exportarDominical="exportarDominical"
      	    	@exportarSabatino="exportarSabatino"
      	    	@dialogBonos="dialogBonos.estatus = true"
      	    	@dialogSinTeacher="dialogSinTeacher = true"
      	    	@dialogRolados="dialogRolados.estatus = true"
      	    	@cargarCatalogos="cargarCatalogos"
      	    	@cargarBloque="cargarBloque"
      	    	@consultar="consultar"
      	    	@dialogNoRolados="dialogNoRolados.estatus = true"
      	    	@enviarRol="enviarRol"
      	    	@dialogConfirmados="dialogConfirmados.estatus = true"
      	    	@verificacionRol="verificacionRol"
      	    	@dialogHorasDobles="dialogDobles.estatus = true"
      	    />

      	    <botonesLider
      	    	v-if="[1552,1127,1336,1177,886,1593].includes( this.getdatosUsuario.iderp )"
      	    	@abrirComparativa="abrirComparativa"
      	    	@exportarRol="exportarRol3"
      	    	@exportarDominical="exportarDominical"
      	    	@exportarSabatino="exportarSabatino"
      	    	@dialogBonos="dialogBonos.estatus = true"
      	    	@dialogSinTeacher="dialogSinTeacher = true"
      	    	@dialogRolados="dialogRolados.estatus = true"
      	    	@cargarCatalogos="cargarCatalogos"
      	    	@cargarBloque="cargarBloque"
      	    	@consultar="consultar"
      	    	@dialogNoRolados="dialogNoRolados.estatus = true"
      	    	@enviarRol="enviarRol"
      	    	@dialogConfirmados="dialogConfirmados.estatus = true"
      	    	@verificacionRol="verificacionRol"
      	    	@dialogHorasDobles="dialogDobles.estatus = true"
      	    />

      	  </v-card-title>

      	  <!-- Tabla y filtros -->
      	  <v-card-text>

      	  	<!-- Filtro de ciclos -->
      	    <v-row>
              <v-col cols="12" sm="6" lg="4" xl="3">
                <v-autocomplete
                  filled
                  dense
                  clearable
                  v-model="ciclo"
                  :items="ciclos"
                  label="Selecciona ciclo"
                  persistent-hint
                  single-line
                  hide-details
                  return-object
                  item-text="ciclo"
                  item-value="id_ciclo"
                >
                </v-autocomplete>
              </v-col>

              <v-col cols="12" sm="6" lg="4" xl="3">
                <v-radio-group
						      v-model="escuela"
						      row
						      hide-details
						    >
						      <v-radio
						        label="INBI"
						        :value="1"
						      ></v-radio>
						      <v-radio
						        label="FAST"
						        :value="2"
						      ></v-radio>
						      <v-radio
						        label="AMBAS"
						        :value="3"
						      ></v-radio>
						    </v-radio-group>
              </v-col>

              <v-col cols="12" sm="6" lg="4" xl="3" v-if="ultimaActualizacion">
                Última actualización
                <br/>Usuario: <b>{{ ultimaActualizacion.nombre_completo }}</b>
                <br/>Fecha: <b>{{ ultimaActualizacion.fecha_creacion }}</b>
              </v-col>
            </v-row>

            <!-- Mostrar información por bloques -->
            <v-row justify="space-between">
            	<v-col cols="12" md="6" lg="4">
            		<v-select
            		  :items="[{ bloque: 'Semana 1', value: 1},{ bloque: 'Semana 2', value: 2},{ bloque: 'Semana 3', value: 3},{ bloque: 'Semana 4', value: 4}]"
            		  v-model="selectedHeaders"
            		  label="SEMANA"
            		  filled
            		  hide-details
            		  item-text="bloque"
                  item-value="value"
            		></v-select>
            	</v-col>

              <v-col cols="12" md="6" lg="6">
                <v-text-field
                  v-model="search"
                  label="Búscar"
                  dense
                  filled
                  hide-details
                  single-line
                  clearable
                ></v-text-field>
              </v-col>
            </v-row>


            <!-- Check para los eliminados y optimizados -->
            <v-row>
            	<v-col cols="12" md="3" lg="2">
            		<v-checkbox
						      v-model="checkEliminados"
						      label="Eliminados"
						      small
						      hide-details
						    ></v-checkbox>

						    <v-checkbox
						      v-model="checkFaltistas"
						      label="Sin asitencia"
						      small
						      hide-details
						    ></v-checkbox>
            	</v-col>

            	<!-- Sleleccionar un curso -->
            	<v-col cols="12" md="3" lg="2">
						    <v-autocomplete
						    	filled
						      :items="[{id: 1, texto: 'Dominical'},{id: 2, texto: 'Sabatino'},{id: 3, texto: 'Entre semana'}, {id: 4, texto: 'TODO MENOS SABADOS'}, {id: 5, texto: 'HORAS 1.5'}]"
						      v-model="curso"
						      item-text="texto"
						      item-value="id"
						      label="Frecuencia"
						      clearable
						      dense
                  single-line
						    ></v-autocomplete>
            	</v-col>

            	<!-- Seleccionar la modalidad -->
            	<v-col cols="12" md="3" lg="2">
						    <v-autocomplete
						    	filled
						      :items="[{id: 1, texto: 'Online'},{id: 0, texto: 'Presencial'}]"
						      v-model="modalidad"
						      item-text="texto"
						      item-value="id"
						      label="Modalidad"
						      clearable
						      dense
                  single-line
						    ></v-autocomplete>
            	</v-col>

            	<!-- Seleccionar un nivel -->
            	<v-col cols="12" md="3" lg="2">
						    <v-autocomplete
						    	filled
						      :items="niveles"
						      v-model="nivel"
						      label="Nivel"
						      clearable
						      dense
                  single-line
						    ></v-autocomplete>
            	</v-col>

            	<!-- Seleccionar un plantel -->
            	<v-col cols="12" md="3" lg="2">
						    <v-autocomplete
						    	filled
						      :items="planteles"
						      v-model="plantel"
						      label="Plantel"
						      clearable
						      dense
						      item-text="plantel"
						      item-value="plantel"
                  single-line
						    ></v-autocomplete>
            	</v-col>

            	<!-- Seleccionar un horario -->
            	<v-col cols="12" md="3" lg="2">
						    <v-autocomplete
						    	filled
						      :items="horarios"
						      v-model="horario"
						      label="Horario"
						      clearable
						      dense
                  single-line
						    ></v-autocomplete>
            	</v-col>

            	<!-- Filtrar por teacher -->
            	<v-col cols="12" md="3" lg="4">
						    <v-autocomplete
						    	filled
						      :items="teachersFiltros"
						      v-model="teacherFilter"
						      label="Teacher"
						      clearable
						      dense
                  single-line
						    ></v-autocomplete>
            	</v-col>

            	<!-- Filtrar por teacher -->
            	<v-col cols="12" md="3" lg="4">
						    <h3> TOTAL DE GRUPOS: {{ filterGrupos.length }} </h3>
						    <h3> ASIGNADOS: {{ gruposCompletos.length }} </h3>
						    <h3> FALTANTES: {{ gruposFaltantes.length }} </h3>
						    <h3> SIN BITACORA: {{ filterSinBitacora }} </h3>
            	</v-col>

            	<!-- Filtrar por teacher -->
            	<v-col cols="12" md="3" lg="4">
						    <h3> HORAS POR SEMANA: {{ horasSemana }} </h3>
						    <h3> HORAS POR CATORCENA: {{ horasCatorcena }} </h3>
						    <h3> HORAS POR CICLO: {{ horasCiclo }} </h3>
            	</v-col>

            </v-row>
		        
		        <!-- Tabla con toda la información -->
		        <v-data-table
		          :headers="headers"
		          :items="filterGrupos"
		          class="elevation-0"
		          height="500"
		          dense
		          fixed-header
		          :search="search"
		          multi-sort
		          :loading="cargar"
		        > 
		        	<template v-slot:item.grupo="{ item }">
		        		<span type="button" v-if="item.deleted == 0" @click="codigo = item, dialogZoom = true"> {{ item.grupo }} </span>
		        		<span type="button" v-else class="red--text" @click="codigo = item, dialogZoom = true"> {{ item.grupo }} </span>
		          </template>

		          <template v-slot:item.salon="props">
				        <v-edit-dialog
				          :return-value.sync="props.item.salon_2"
				          @save="saveSalon( props.item )"
				          large
				        >
				          {{ props.item.salon_2 ? props.item.salon_2 : 'SIN SALÓN' }}
				          <template v-slot:input>
				            <v-autocomplete
		                  :items="salones"
		                  v-model="props.item.id_salon_2"
		                  label="Salone"
		                  dense
		                  filled
		                  item-value="id_salon"
		                  item-text="salon"
		                  class="ma-2"
		                  hide-details
		                ></v-autocomplete>
				          </template>
				        </v-edit-dialog>
				      </template>

		          <template v-slot:item.optimizado="{ item }">
		            <v-chip v-if="item.optimizado == 1" small rounded color="green" dark >Optimizado</v-chip>
		          </template>

		          <template v-slot:item.modalidad="{ item }">
		            <v-chip v-if="item.modalidad == 1" small rounded color="green"  dark >Online</v-chip>
		            <v-chip v-if="item.modalidad == 0" small rounded color="orange" dark >Presencial</v-chip>
		          </template>

		          <template v-slot:item.plantel="{ item }">
		            <span class="subrayado" @click="codigo = item, dialogMaps.estatus = true">{{ item.plantel }}</span>
		          </template>

		          <template v-slot:item.id_unidad_negocio="{ item }">
		            <v-chip v-if="item.id_unidad_negocio == 1" small rounded color="blue lighten-2" dark >INBI</v-chip>
		            <v-chip v-else small rounded color="blue darken-2" dark >FAST</v-chip>
		          </template>

		          <!-- Editar Bloque 1 -->
		          <template v-slot:item.lu1_n="{ item }" class="text-subtitle-1">
		            <span @click="item.lu1_bloqueo ? '' : abrirDialogMaestro( item, 'lu1' )" type="button" class="text-caption" v-if="item.lunes == 1 || item.sabado == 1 || item.domingo == 1" :class="item.lu1_falta ? 'red darken-2 white--text' : '' " 
		            	:id="!item.grupo.match('ONLINE') && item.lu1_fecha <= fecha_hoy && !item.lu1_hora_asistencia ? 'yellow' : '' ">
		            	<v-icon :color="item.lu1_confirmacion == 1 ? 'green' :  item.lu1_confirmacion == 2 ? 'red' : 'grey'">mdi-circle-medium</v-icon>
		            	{{  item.lu1_n ? item.lu1_n : 'SIN TEACHER' }}
		            </span>

		            <!-- ASISTENCIA -->
		            {{ item.lu1_hora_asistencia ? item.lu1_hora_asistencia : '' }}

		            <span type="button" v-if="item.lunes == 0 && item.sabado == 0 && item.domingo == 0">
		            	<v-btn tile color="black"></v-btn>
		            </span>
		          	<span 
		            	v-if="item.lunes == 1 || item.sabado == 1 || item.domingo == 1"
		            	type="button" 
		            	@click="bloque =  'lu1', grupo = item.iderp, asistencia = item.lu1, dialogAsistencia = true"
		            >
		            	<v-chip color="green" dark small v-if="item.lu1_as && item.lu1_as.substr( item.lu1_as.length - 1,1 ) == 1 ">Asistió</v-chip>
			            <v-tooltip bottom>
							      <template v-slot:activator="{ on, attrs }">
	            				<v-chip 
							          v-bind="attrs"
							          v-on="on"
	            					color="red" 
	            					dark 
	            					small 
	            					v-if="item.lu1_as && item.lu1_as.substr( item.lu1_as.length - 1,1 ) == 2 "
	            				>
	            					No Asistió
	            				</v-chip>
							      </template>
							      <span>{{ item.lu1_as }}</span>
							    </v-tooltip>
		            </span>
		            <div v-if="( item.lunes == 1 || item.sabado == 1 || item.domingo == 1 )&& item.llu1 ">
                  <v-chip small rounded color="pink" dark v-for="(leccion, i ) in item.llu1.split(',')" :key="i" class="mt-1">
                    {{ leccion }}
                  </v-chip>
                </div>
                <div v-if="( item.lunes == 1 || item.sabado == 1 || item.domingo == 1 ) && item.lu1_bitacora ">
                  <v-chip small rounded color="lime accent-4" dark class="mt-1 black--text" @click="verBitacora('lu1', item.lu1, item.idrol_clases)">
                    Bitacora
                  </v-chip>
                </div>
		          </template>

		          <template v-slot:item.ma1_n="{ item }" class="text-subtitle-1">
		            <span @click="item.ma1_bloqueo ? '' : abrirDialogMaestro( item, 'ma1' )" type="button" class="text-caption" v-if="item.martes == 1 || item.sabado == 1 || item.domingo == 1" :class="item.ma1_falta ? 'red darken-2 white--text' : '' " 
		            	:id="!item.grupo.match('ONLINE') && item.ma1_fecha <= fecha_hoy && !item.ma1_hora_asistencia ? 'yellow' : '' ">
		            	<v-icon :color="item.ma1_confirmacion == 1 ? 'green' :  item.ma1_confirmacion == 2 ? 'red' : 'grey'">mdi-circle-medium</v-icon>
		            	{{ item.ma1_n ? item.ma1_n : 'SIN TEACHER' }}
		            </span>

		            <!-- ASISTENCIA -->
		            {{ item.ma1_hora_asistencia ? item.ma1_hora_asistencia : '' }}
		            <span type="button" v-if="item.martes == 0 && item.sabado == 0 && item.domingo == 0">
		            	<v-btn tile color="black"></v-btn>
		            </span>
		          	<span 
		            	v-if="item.martes == 1 || item.sabado == 1 || item.domingo == 1"
		            	type="button" 
		            	@click="bloque =  'ma1', grupo = item.iderp, asistencia = item.ma1, dialogAsistencia = true"
		            >
			            <v-chip color="green" dark small v-if="item.ma1_as && item.ma1_as.substr( item.ma1_as.length - 1,1 ) == 1 ">Asistió</v-chip>
			            <v-tooltip bottom>
							      <template v-slot:activator="{ on, attrs }">
	            				<v-chip 
							          v-bind="attrs"
							          v-on="on"
	            					color="red" 
	            					dark 
	            					small 
	            					v-if="item.ma1_as && item.ma1_as.substr( item.ma1_as.length - 1,1 ) == 2 "
	            				>
	            					No Asistió
	            				</v-chip>
							      </template>
							      <span>{{ item.ma1_as }}</span>
							    </v-tooltip>
		          	</span>
		          	<div v-if="( item.martes == 1 || item.sabado == 1 || item.domingo == 1 )&& item.lma1 ">
                  <v-chip small rounded color="pink" dark v-for="(leccion, i ) in item.lma1.split(',')" :key="i" class="mt-1">
                    {{ leccion }}
                  </v-chip>
                </div>
                <div v-if="( item.martes == 1 || item.sabado == 1 || item.domingo == 1 )&& item.ma1_bitacora ">
                  <v-chip small rounded color="lime accent-4" dark class="mt-1 black--text" @click="verBitacora('ma1', item.ma1, item.idrol_clases)">
                    Bitacora
                  </v-chip>
                </div>
		          </template>

		          <template v-slot:item.mi1_n="{ item }" class="text-subtitle-1">
		            <span @click="item.mi1_bloqueo ? '' : abrirDialogMaestro( item, 'mi1' )" type="button" class="text-caption" v-if="item.miercoles == 1 || item.sabado == 1 || item.domingo == 1" :class="item.mi1_falta ? 'red darken-2 white--text' : '' " 
		            	:id="!item.grupo.match('ONLINE') && item.mi1_fecha <= fecha_hoy && !item.mi1_hora_asistencia ? 'yellow' : '' ">
		            	<v-icon :color="item.mi1_confirmacion == 1 ? 'green' :  item.mi1_confirmacion == 2 ? 'red' : 'grey'">mdi-circle-medium</v-icon>
		            	{{ item.mi1_n ? item.mi1_n : 'SIN TEACHER' }}
		            </span>

		            <!-- ASISTENCIA -->
		            {{ item.mi1_hora_asistencia ? item.mi1_hora_asistencia : '' }}
		          	<span type="button" v-if="item.miercoles == 0 && item.sabado == 0 && item.domingo == 0">
		            	<v-btn tile color="black"></v-btn>
		            </span>
		          	<span 
		            	v-if="item.miercoles == 1 || item.sabado == 1 || item.domingo == 1"
		            	type="button" 
		            	@click="bloque =  'mi1', grupo = item.iderp, asistencia = item.mi1, dialogAsistencia = true"
		            >
			            <v-chip color="green" dark small v-if="item.mi1_as && item.mi1_as.substr( item.mi1_as.length - 1,1 ) == 1 ">Asistió</v-chip>
			            <v-tooltip bottom>
							      <template v-slot:activator="{ on, attrs }">
	            				<v-chip 
							          v-bind="attrs"
							          v-on="on"
	            					color="red" 
	            					dark 
	            					small 
	            					v-if="item.mi1_as && item.mi1_as.substr( item.mi1_as.length - 1,1 ) == 2 "
	            				>
	            					No Asistió
	            				</v-chip>
							      </template>
							      <span>{{ item.mi1_as }}</span>
							    </v-tooltip>
		          	</span>
		          	<div v-if="( item.miercoles == 1 || item.sabado == 1 || item.domingo == 1 )&& item.lmi1 ">
                  <v-chip small rounded color="pink" dark v-for="(leccion, i ) in item.lmi1.split(',')" :key="i" class="mt-1">
                    {{ leccion }}
                  </v-chip>
                </div>
                <div v-if="( item.miercoles == 1 || item.sabado == 1 || item.domingo == 1 )&& item.mi1_bitacora ">
                  <v-chip small rounded color="lime accent-4" dark class="mt-1 black--text" @click="verBitacora('mi1', item.mi1, item.idrol_clases)">
                    Bitacora
                  </v-chip>
                </div>
		          </template>

		          <template v-slot:item.ju1_n="{ item }" class="text-subtitle-1">
		            <span @click="item.ju1_bloqueo ? '' : abrirDialogMaestro( item, 'ju1' )" type="button" class="text-caption" v-if="item.jueves == 1 || item.sabado == 1 || item.domingo == 1" :class="item.ju1_falta ? 'red darken-2 white--text' : '' " 
		            	:id="!item.grupo.match('ONLINE') && item.ju1_fecha <= fecha_hoy && !item.ju1_hora_asistencia ? 'yellow' : '' ">
		            	<v-icon :color="item.ju1_confirmacion == 1 ? 'green' :  item.ju1_confirmacion == 2 ? 'red' : 'grey'">mdi-circle-medium</v-icon>
		            	{{ item.ju1_n ? item.ju1_n : 'SIN TEACHER' }}
		            </span>

		            <!-- ASISTENCIA -->
		            {{ item.ju1_hora_asistencia ? item.ju1_hora_asistencia : '' }}
		          	<span type="button" v-if="item.jueves == 0 && item.sabado == 0 && item.domingo == 0">
		            	<v-btn tile color="black"></v-btn>
		            </span>
		          	<span 
		            	v-if="item.jueves == 1 || item.sabado == 1 || item.domingo == 1"
		            	type="button" 
		            	@click="bloque =  'ju1', grupo = item.iderp, asistencia = item.ju1, dialogAsistencia = true"
		            >
			            <v-chip color="green" dark small v-if="item.ju1_as && item.ju1_as.substr( item.ju1_as.length - 1,1 ) == 1 ">Asistió</v-chip>
			            <v-tooltip bottom>
							      <template v-slot:activator="{ on, attrs }">
	            				<v-chip 
							          v-bind="attrs"
							          v-on="on"
	            					color="red" 
	            					dark 
	            					small 
	            					v-if="item.ju1_as && item.ju1_as.substr( item.ju1_as.length - 1,1 ) == 2 "
	            				>
	            					No Asistió
	            				</v-chip>
							      </template>
							      <span>{{ item.ju1_as }}</span>
							    </v-tooltip>
		          	</span>
		          	<div v-if="( item.jueves == 1 || item.sabado == 1 || item.domingo == 1 )&& item.lju1 ">
                  <v-chip small rounded color="pink" dark v-for="(leccion, i ) in item.lju1.split(',')" :key="i" class="mt-1">
                    {{ leccion }}
                  </v-chip>
                </div>
                <div v-if="( item.jueves == 1 || item.sabado == 1 || item.domingo == 1 )&& item.ju1_bitacora ">
                  <v-chip small rounded color="lime accent-4" dark class="mt-1 black--text" @click="verBitacora('ju1', item.ju1, item.idrol_clases)">
                    Bitacora
                  </v-chip>
                </div>
		          </template>

		          <template v-slot:item.vi1_n="{ item }" class="text-subtitle-1">
		            <span @click="item.vi1_bloqueo ? '' : abrirDialogMaestro( item, 'vi1' )" type="button" class="text-caption" v-if="item.viernes == 1 || item.sabado == 1 || item.domingo == 1" :class="item.vi1_falta ? 'red darken-2 white--text' : '' " 
		            	:id="!item.grupo.match('ONLINE') && item.vi1_fecha <= fecha_hoy && !item.vi1_hora_asistencia ? 'yellow' : '' ">
		            	<v-icon :color="item.vi1_confirmacion == 1 ? 'green' :  item.vi1_confirmacion == 2 ? 'red' : 'grey'">mdi-circle-medium</v-icon>
		            	{{ item.vi1_n ? item.vi1_n : 'SIN TEACHER' }}
		            </span>

		            <!-- ASISTENCIA -->
		            {{ item.vi1_hora_asistencia ? item.vi1_hora_asistencia : '' }}
		          	<span type="button" v-if="item.viernes == 0 && item.sabado == 0 && item.domingo == 0">
		            	<v-btn tile color="black"></v-btn>
		            </span>
		          	<span 
		            	v-if="item.viernes == 1 || item.sabado == 1 || item.domingo == 1" 
		            	type="button" 
		            	@click="bloque =  'vi1', grupo = item.iderp, asistencia = item.vi1, dialogAsistencia = true"
		            >
			            <v-chip color="green" dark small v-if="item.vi1_as && item.vi1_as.substr( item.vi1_as.length - 1,1 ) == 1 ">Asistió</v-chip>
			            <v-tooltip bottom>
							      <template v-slot:activator="{ on, attrs }">
	            				<v-chip 
							          v-bind="attrs"
							          v-on="on"
	            					color="red" 
	            					dark 
	            					small 
	            					v-if="item.vi1_as && item.vi1_as.substr( item.vi1_as.length - 1,1 ) == 2 "
	            				>
	            					No Asistió
	            				</v-chip>
							      </template>
							      <span>{{ item.vi1_as }}</span>
							    </v-tooltip>
		          	</span>
		          	<div v-if="( item.viernes == 1 || item.sabado == 1 || item.domingo == 1 )&& item.lvi1 ">
                  <v-chip small rounded color="pink" dark v-for="(leccion, i ) in item.lvi1.split(',')" :key="i" class="mt-1">
                    {{ leccion }}
                  </v-chip>
                </div>
                <div v-if="( item.viernes == 1 || item.sabado == 1 || item.domingo == 1 )&& item.vi1_bitacora ">
                  <v-chip small rounded color="lime accent-4" dark class="mt-1 black--text" @click="verBitacora('vi1', item.vi1, item.idrol_clases)">
                    Bitacora
                  </v-chip>
                </div>
		          </template>

		          <!-- Editar Bloque 2 -->
		          <template v-slot:item.lu2_n="{ item }" class="text-subtitle-1">
		            <span @click="item.lu2_bloqueo ? '' : abrirDialogMaestro( item, 'lu2' )" type="button" class="text-caption" v-if="item.lunes == 1 || item.sabado == 1 || item.domingo == 1" :class="item.lu2_falta ? 'red darken-2 white--text' : '' " 
		            	:id="!item.grupo.match('ONLINE') && item.lu2_fecha <= fecha_hoy && !item.lu2_hora_asistencia ? 'yellow' : '' ">
		            	<v-icon :color="item.lu2_confirmacion == 1 ? 'green' :  item.lu2_confirmacion == 2 ? 'red' : 'grey'">mdi-circle-medium</v-icon>
		            	{{ item.lu2_n ? item.lu2_n : 'SIN TEACHER' }}
		            </span>

		            <!-- ASISTENCIA -->
		            {{ item.lu2_hora_asistencia ? item.lu2_hora_asistencia : '' }}
		          	<span type="button" v-if="item.lunes == 0 && item.sabado == 0 && item.domingo == 0">
		            	<v-btn tile color="black"></v-btn>
		            </span>
		          	<span 
		            	v-if="item.lunes == 1 || item.sabado == 1 || item.domingo == 1"
		            	type="button" 
		            	@click="bloque =  'lu2', grupo = item.iderp, asistencia = item.lu2, dialogAsistencia = true"
		            >
		            	<v-chip color="green" dark small v-if="item.lu2_as && item.lu2_as.substr( item.lu2_as.length - 1,1 ) == 1 ">Asistió</v-chip>
			            <v-tooltip bottom>
							      <template v-slot:activator="{ on, attrs }">
	            				<v-chip 
							          v-bind="attrs"
							          v-on="on"
	            					color="red" 
	            					dark 
	            					small 
	            					v-if="item.lu2_as && item.lu2_as.substr( item.lu2_as.length - 1,1 ) == 2 "
	            				>
	            					No Asistió
	            				</v-chip>
							      </template>
							      <span>{{ item.lu2_as }}</span>
							    </v-tooltip>
		            </span>
		            <div v-if="( item.lunes == 1 || item.sabado == 1 || item.domingo == 1 )&& item.llu2 ">
                  <v-chip small rounded color="pink" dark v-for="(leccion, i ) in item.llu2.split(',')" :key="i" class="mt-1">
                    {{ leccion }}
                  </v-chip>
                </div>
                <div v-if="( item.lunes == 1 || item.sabado == 1 || item.domingo == 1 )&& item.lu2_bitacora ">
                  <v-chip small rounded color="lime accent-4" dark class="mt-1 black--text" @click="verBitacora('lu2', item.lu2, item.idrol_clases)">
                    Bitacora
                  </v-chip>
                </div>
		          </template>

		          <template v-slot:item.ma2_n="{ item }" class="text-subtitle-1">
		            <span @click="item.ma2_bloqueo ? '' : abrirDialogMaestro( item, 'ma2' )" type="button" class="text-caption" v-if="item.martes == 1 || item.sabado == 1 || item.domingo == 1" :class="item.ma2_falta ? 'red darken-2 white--text' : '' " 
		            	:id="!item.grupo.match('ONLINE') && item.ma2_fecha <= fecha_hoy && !item.ma2_hora_asistencia ? 'yellow' : '' ">
		            	<v-icon :color="item.ma2_confirmacion == 1 ? 'green' :  item.ma2_confirmacion == 2 ? 'red' : 'grey'">mdi-circle-medium</v-icon>
		            	{{ item.ma2_n ? item.ma2_n : 'SIN TEACHER' }}
		            </span>

		            <!-- ASISTENCIA -->
		            {{ item.ma2_hora_asistencia ? item.ma2_hora_asistencia : '' }}
		          	<span type="button" v-if="item.martes == 0 && item.sabado == 0 && item.domingo == 0">
		            	<v-btn tile color="black"></v-btn>
		            </span>
		          	<span 
		            	v-if="item.martes == 1 || item.sabado == 1 || item.domingo == 1"
		            	type="button" 
		            	@click="bloque =  'ma2', grupo = item.iderp, asistencia = item.ma2, dialogAsistencia = true"
		            >
			            <v-chip color="green" dark small v-if="item.ma2_as && item.ma2_as.substr( item.ma2_as.length - 1,1 ) == 1 ">Asistió</v-chip>
			            <v-tooltip bottom>
							      <template v-slot:activator="{ on, attrs }">
	            				<v-chip 
							          v-bind="attrs"
							          v-on="on"
	            					color="red" 
	            					dark 
	            					small 
	            					v-if="item.ma2_as && item.ma2_as.substr( item.ma2_as.length - 1,1 ) == 2 "
	            				>
	            					No Asistió
	            				</v-chip>
							      </template>
							      <span>{{ item.ma1_as }}</span>
							    </v-tooltip>
		          	</span>
		          	<div v-if="( item.martes == 1 || item.sabado == 1 || item.domingo == 1 )&& item.lma2 ">
                  <v-chip small rounded color="pink" dark v-for="(leccion, i ) in item.lma2.split(',')" :key="i" class="mt-1">
                    {{ leccion }}
                  </v-chip>
                </div>
                <div v-if="( item.martes == 1 || item.sabado == 1 || item.domingo == 1 )&& item.ma2_bitacora ">
                  <v-chip small rounded color="lime accent-4" dark class="mt-1 black--text" @click="verBitacora('ma2', item.ma2, item.idrol_clases)">
                    Bitacora
                  </v-chip>
                </div>
		          </template>

		          <template v-slot:item.mi2_n="{ item }" class="text-subtitle-1">
		            <span @click="item.mi2_bloqueo ? '' : abrirDialogMaestro( item, 'mi2' )" type="button" class="text-caption" v-if="item.miercoles == 1 || item.sabado == 1 || item.domingo == 1" :class="item.mi2_falta ? 'red darken-2 white--text' : '' " 
		            	:id="!item.grupo.match('ONLINE') && item.mi2_fecha <= fecha_hoy && !item.mi2_hora_asistencia ? 'yellow' : '' ">
		            	<v-icon :color="item.mi2_confirmacion == 1 ? 'green' :  item.mi2_confirmacion == 2 ? 'red' : 'grey'">mdi-circle-medium</v-icon>
		            	{{ item.mi2_n ? item.mi2_n : 'SIN TEACHER' }}
		            </span>

		            <!-- ASISTENCIA -->
		            {{ item.mi2_hora_asistencia ? item.mi2_hora_asistencia : '' }}
		          	<span type="button" v-if="item.miercoles == 0 && item.sabado == 0 && item.domingo == 0">
		            	<v-btn tile color="black"></v-btn>
		            </span>
		          	<span 
		            	v-if="item.miercoles == 1 || item.sabado == 1 || item.domingo == 1"
		            	type="button" 
		            	@click="bloque =  'mi2', grupo = item.iderp, asistencia = item.mi2, dialogAsistencia = true"
		            >
			            <v-chip color="green" dark small v-if="item.mi1_as && item.mi1_as.substr( item.mi1_as.length - 1,1 ) == 1 ">Asistió</v-chip>
			            <v-tooltip bottom>
							      <template v-slot:activator="{ on, attrs }">
	            				<v-chip 
							          v-bind="attrs"
							          v-on="on"
	            					color="red" 
	            					dark 
	            					small 
	            					v-if="item.mi1_as && item.mi1_as.substr( item.mi1_as.length - 1,1 ) == 2 "
	            				>
	            					No Asistió
	            				</v-chip>
							      </template>
							      <span>{{ item.mi1_as }}</span>
							    </v-tooltip>
		          	</span>
		          	<div v-if="( item.miercoles == 1 || item.sabado == 1 || item.domingo == 1 )&& item.lmi2 ">
                  <v-chip small rounded color="pink" dark v-for="(leccion, i ) in item.lmi2.split(',')" :key="i" class="mt-1">
                    {{ leccion }}
                  </v-chip>
                </div>
                <div v-if="( item.miercoles == 1 || item.sabado == 1 || item.domingo == 1 )&& item.mi2_bitacora ">
                  <v-chip small rounded color="lime accent-4" dark class="mt-1 black--text" @click="verBitacora('mi2', item.mi2, item.idrol_clases)">
                    Bitacora
                  </v-chip>
                </div>
		          </template>

		          <template v-slot:item.ju2_n="{ item }" class="text-subtitle-1">
		            <span @click="item.ju2_bloqueo ? '' : abrirDialogMaestro( item, 'ju2' )" type="button" class="text-caption" v-if="item.jueves == 1 || item.sabado == 1 || item.domingo == 1" :class="item.ju2_falta ? 'red darken-2 white--text' : '' " 
		            	:id="!item.grupo.match('ONLINE') && item.ju2_fecha <= fecha_hoy && !item.ju2_hora_asistencia ? 'yellow' : '' ">
		            	<v-icon :color="item.ju2_confirmacion == 1 ? 'green' :  item.ju2_confirmacion == 2 ? 'red' : 'grey'">mdi-circle-medium</v-icon>
		            	{{ item.ju2_n ? item.ju2_n : 'SIN TEACHER' }}
		            </span>

		            <!-- ASISTENCIA -->
		            {{ item.ju2_hora_asistencia ? item.ju2_hora_asistencia : '' }}
		          	<span type="button" v-if="item.jueves == 0 && item.sabado == 0 && item.domingo == 0">
		            	<v-btn tile color="black"></v-btn>
		            </span>
		          	<span 
		            	v-if="item.jueves == 1 || item.sabado == 1 || item.domingo == 1"
		            	type="button" 
		            	@click="bloque =  'ju2', grupo = item.iderp, asistencia = item.ju2, dialogAsistencia = true"
		            >
			            <v-chip color="green" dark small v-if="item.ju2_as && item.ju2_as.substr( item.ju2_as.length - 1,1 ) == 1 ">Asistió</v-chip>
			            <v-tooltip bottom>
							      <template v-slot:activator="{ on, attrs }">
	            				<v-chip 
							          v-bind="attrs"
							          v-on="on"
	            					color="red" 
	            					dark 
	            					small 
	            					v-if="item.ju2_as && item.ju2_as.substr( item.ju2_as.length - 1,1 ) == 2 "
	            				>
	            					No Asistió
	            				</v-chip>
							      </template>
							      <span>{{ item.ju2_as }}</span>
							    </v-tooltip>
		          	</span>
		          	<div v-if="( item.jueves == 1 || item.sabado == 1 || item.domingo == 1 )&& item.lju2 ">
                  <v-chip small rounded color="pink" dark v-for="(leccion, i ) in item.lju2.split(',')" :key="i" class="mt-1">
                    {{ leccion }}
                  </v-chip>
                </div>
                <div v-if="( item.jueves == 1 || item.sabado == 1 || item.domingo == 1 )&& item.ju2_bitacora ">
                  <v-chip small rounded color="lime accent-4" dark class="mt-1 black--text" @click="verBitacora('ju2', item.ju2, item.idrol_clases)">
                    Bitacora
                  </v-chip>
                </div>
		          </template>

		          <template v-slot:item.vi2_n="{ item }" class="text-subtitle-1">
		            <span @click="item.vi2_bloqueo ? '' : abrirDialogMaestro( item, 'vi2' )" type="button" class="text-caption" v-if="item.viernes == 1 || item.sabado == 1 || item.domingo == 1" :class="item.vi2_falta ? 'red darken-2 white--text' : '' " 
		            	:id="!item.grupo.match('ONLINE') && item.vi2_fecha <= fecha_hoy && !item.vi2_hora_asistencia ? 'yellow' : '' ">
		            	<v-icon :color="item.vi2_confirmacion == 1 ? 'green' :  item.vi2_confirmacion == 2 ? 'red' : 'grey'">mdi-circle-medium</v-icon>
		            	{{ item.vi2_n ? item.vi2_n : 'SIN TEACHER' }}
		            </span>

		            <!-- ASISTENCIA -->
		            {{ item.vi2_hora_asistencia ? item.vi2_hora_asistencia : '' }}
		          	<span type="button" v-if="item.viernes == 0 && item.sabado == 0 && item.domingo == 0">
		            	<v-btn tile color="black"></v-btn>
		            </span>
		          	<span 
		            	v-if="item.viernes == 1 || item.sabado == 1 || item.domingo == 1" 
		            	type="button" 
		            	@click="bloque =  'vi2', grupo = item.iderp, asistencia = item.vi2, dialogAsistencia = true"
		            >
			            <v-chip color="green" dark small v-if="item.vi2_as && item.vi2_as.substr( item.vi2_as.length - 1,1 ) == 1 ">Asistió</v-chip>
			            <v-tooltip bottom>
							      <template v-slot:activator="{ on, attrs }">
	            				<v-chip 
							          v-bind="attrs"
							          v-on="on"
	            					color="red" 
	            					dark 
	            					small 
	            					v-if="item.vi2_as && item.vi2_as.substr( item.vi2_as.length - 1,1 ) == 2 "
	            				>
	            					No Asistió
	            				</v-chip>
							      </template>
							      <span>{{ item.vi2_as }}</span>
							    </v-tooltip>
		          	</span>
		          	<div v-if="( item.viernes == 1 || item.sabado == 1 || item.domingo == 1 )&& item.lvi2 ">
                  <v-chip small rounded color="pink" dark v-for="(leccion, i ) in item.lvi2.split(',')" :key="i" class="mt-1">
                    {{ leccion }}
                  </v-chip>
                </div>
                <div v-if="( item.viernes == 1 || item.sabado == 1 || item.domingo == 1 )&& item.vi2_bitacora ">
                  <v-chip small rounded color="lime accent-4" dark class="mt-1 black--text" @click="verBitacora('vi2', item.vi2, item.idrol_clases)">
                    Bitacora
                  </v-chip>
                </div>
		          </template>

		          <!-- Editar Bloque 3 -->
		          <template v-slot:item.lu3_n="{ item }" class="text-subtitle-1">
		            <span @click="item.lu3_bloqueo ? '' : abrirDialogMaestro( item, 'lu3' )" type="button" class="text-caption" v-if="item.lunes == 1 || item.sabado == 1 || item.domingo == 1" :class="item.lu3_falta ? 'red darken-2 white--text' : '' " 
		            	:id="!item.grupo.match('ONLINE') && item.lu3_fecha <= fecha_hoy && !item.lu3_hora_asistencia ? 'yellow' : '' ">
		            	<v-icon :color="item.lu3_confirmacion == 1 ? 'green' :  item.lu3_confirmacion == 2 ? 'red' : 'grey'">mdi-circle-medium</v-icon>
		            	{{ item.lu3_n ? item.lu3_n : 'SIN TEACHER' }}
		            </span>

		            <!-- ASISTENCIA -->
		            {{ item.lu3_hora_asistencia ? item.lu3_hora_asistencia : '' }}
		          	<span type="button" v-if="item.lunes == 0 && item.sabado == 0 && item.domingo == 0">
		            	<v-btn tile color="black"></v-btn>
		            </span>
		          	<span 
		            	v-if="item.lunes == 1 || item.sabado == 1 || item.domingo == 1"
		            	type="button" 
		            	@click="bloque =  'lu3', grupo = item.iderp, asistencia = item.lu3, dialogAsistencia = true"
		            >
		            	<v-chip color="green" dark small v-if="item.lu3_as && item.lu3_as.substr( item.lu3_as.length - 1,1 ) == 1 ">Asistió</v-chip>
			            <v-tooltip bottom>
							      <template v-slot:activator="{ on, attrs }">
	            				<v-chip 
							          v-bind="attrs"
							          v-on="on"
	            					color="red" 
	            					dark 
	            					small 
	            					v-if="item.lu3_as && item.lu3_as.substr( item.lu3_as.length - 1,1 ) == 2 "
	            				>
	            					No Asistió
	            				</v-chip>
							      </template>
							      <span>{{ item.lu3_as }}</span>
							    </v-tooltip>
		            </span>
		            <div v-if="( item.lunes == 1 || item.sabado == 1 || item.domingo == 1 )&& item.llu3 ">
                  <v-chip small rounded color="pink" dark v-for="(leccion, i ) in item.llu3.split(',')" :key="i" class="mt-1">
                    {{ leccion }}
                  </v-chip>
                </div>
                <div v-if="( item.lunes == 1 || item.sabado == 1 || item.domingo == 1 )&& item.lu3_bitacora ">
                  <v-chip small rounded color="lime accent-4" dark class="mt-1 black--text" @click="verBitacora('lu3', item.lu3, item.idrol_clases)">
                    Bitacora
                  </v-chip>
                </div>
		          </template>

		          <template v-slot:item.ma3_n="{ item }" class="text-subtitle-1">
		            <span @click="item.ma3_bloqueo ? '' : abrirDialogMaestro( item, 'ma3' )" type="button" class="text-caption" v-if="item.martes == 1 || item.sabado == 1 || item.domingo == 1" :class="item.ma3_falta ? 'red darken-2 white--text' : '' " 
		            	:id="!item.grupo.match('ONLINE') && item.ma3_fecha <= fecha_hoy && !item.ma3_hora_asistencia ? 'yellow' : '' ">
		            	<v-icon :color="item.ma3_confirmacion == 1 ? 'green' :  item.ma3_confirmacion == 2 ? 'red' : 'grey'">mdi-circle-medium</v-icon>
		            	{{ item.ma3_n ? item.ma3_n : 'SIN TEACHER' }}
		            </span>

		            <!-- ASISTENCIA -->
		            {{ item.ma3_hora_asistencia ? item.ma3_hora_asistencia : '' }}
		          	<span type="button" v-if="item.martes == 0 && item.sabado == 0 && item.domingo == 0">
		            	<v-btn tile color="black"></v-btn>
		            </span>
		          	<span 
		            	v-if="item.martes == 1 || item.sabado == 1 || item.domingo == 1"
		            	type="button" 
		            	@click="bloque =  'ma3', grupo = item.iderp, asistencia = item.ma3, dialogAsistencia = true"
		            >
			            <v-chip color="green" dark small v-if="item.ma3_as && item.ma3_as.substr( item.ma3_as.length - 1,1 ) == 1 ">Asistió</v-chip>
			            <v-tooltip bottom>
							      <template v-slot:activator="{ on, attrs }">
	            				<v-chip 
							          v-bind="attrs"
							          v-on="on"
	            					color="red" 
	            					dark 
	            					small 
	            					v-if="item.ma3_as && item.ma3_as.substr( item.ma3_as.length - 1,1 ) == 2 "
	            				>
	            					No Asistió
	            				</v-chip>
							      </template>
							      <span>{{ item.ma3_as }}</span>
							    </v-tooltip>
		          	</span>
		          	<div v-if="( item.martes == 1 || item.sabado == 1 || item.domingo == 1 )&& item.lma3 ">
                  <v-chip small rounded color="pink" dark v-for="(leccion, i ) in item.lma3.split(',')" :key="i" class="mt-1">
                    {{ leccion }}
                  </v-chip>
                </div>
                <div v-if="( item.martes == 1 || item.sabado == 1 || item.domingo == 1 )&& item.ma3_bitacora ">
                  <v-chip small rounded color="lime accent-4" dark class="mt-1 black--text" @click="verBitacora('ma3', item.ma3, item.idrol_clases)">
                    Bitacora
                  </v-chip>
                </div>
		          </template>

		          <template v-slot:item.mi3_n="{ item }" class="text-subtitle-1">
		            <span @click="item.mi3_bloqueo ? '' : abrirDialogMaestro( item, 'mi3' )" type="button" class="text-caption" v-if="item.miercoles == 1 || item.sabado == 1 || item.domingo == 1" :class="item.mi3_falta ? 'red darken-2 white--text' : '' " 
		            	:id="!item.grupo.match('ONLINE') && item.mi3_fecha <= fecha_hoy && !item.mi3_hora_asistencia ? 'yellow' : '' ">
		            	<v-icon :color="item.mi3_confirmacion == 1 ? 'green' :  item.mi3_confirmacion == 2 ? 'red' : 'grey'">mdi-circle-medium</v-icon>
		            	{{ item.mi3_n ? item.mi3_n : 'SIN TEACHER' }}
		            </span>

		            <!-- ASISTENCIA -->
		            {{ item.mi3_hora_asistencia ? item.mi3_hora_asistencia : '' }}
		          	<span type="button" v-if="item.miercoles == 0 && item.sabado == 0 && item.domingo == 0">
		            	<v-btn tile color="black"></v-btn>
		            </span>
		          	<span 
		            	v-if="item.miercoles == 1 || item.sabado == 1 || item.domingo == 1"
		            	type="button" 
		            	@click="bloque =  'mi3', grupo = item.iderp, asistencia = item.mi3, dialogAsistencia = true"
		            >
			            <v-chip color="green" dark small v-if="item.mi3_as && item.mi3_as.substr( item.mi3_as.length - 1,1 ) == 1 ">Asistió</v-chip>
			            <v-tooltip bottom>
							      <template v-slot:activator="{ on, attrs }">
	            				<v-chip 
							          v-bind="attrs"
							          v-on="on"
	            					color="red" 
	            					dark 
	            					small 
	            					v-if="item.mi3_as && item.mi3_as.substr( item.mi3_as.length - 1,1 ) == 2 "
	            				>
	            					No Asistió
	            				</v-chip>
							      </template>
							      <span>{{ item.mi3_as }}</span>
							    </v-tooltip>
		          	</span>
		          	<div v-if="( item.miercoles == 1 || item.sabado == 1 || item.domingo == 1 )&& item.lmi3 ">
                  <v-chip small rounded color="pink" dark v-for="(leccion, i ) in item.lmi3.split(',')" :key="i" class="mt-1">
                    {{ leccion }}
                  </v-chip>
                </div>
                <div v-if="( item.miercoles == 1 || item.sabado == 1 || item.domingo == 1 )&& item.mi3_bitacora ">
                  <v-chip small rounded color="lime accent-4" dark class="mt-1 black--text" @click="verBitacora('mi3', item.mi3, item.idrol_clases)">
                    Bitacora
                  </v-chip>
                </div>
		          </template>

		          <template v-slot:item.ju3_n="{ item }" class="text-subtitle-1">
		            <span @click="item.ju3_bloqueo ? '' : abrirDialogMaestro( item, 'ju3' )" type="button" class="text-caption" v-if="item.jueves == 1 || item.sabado == 1 || item.domingo == 1" :class="item.ju3_falta ? 'red darken-2 white--text' : '' " 
		            	:id="!item.grupo.match('ONLINE') && item.ju3_fecha <= fecha_hoy && !item.ju3_hora_asistencia ? 'yellow' : '' ">
		            	<v-icon :color="item.ju3_confirmacion == 1 ? 'green' :  item.ju3_confirmacion == 2 ? 'red' : 'grey'">mdi-circle-medium</v-icon>
		            	{{ item.ju3_n ? item.ju3_n : 'SIN TEACHER' }}
		            </span>

		            <!-- ASISTENCIA -->
		            {{ item.ju3_hora_asistencia ? item.ju3_hora_asistencia : '' }}
		          	<span type="button" v-if="item.jueves == 0 && item.sabado == 0 && item.domingo == 0">
		            	<v-btn tile color="black"></v-btn>
		            </span>
		          	<span 
		            	v-if="item.jueves == 1 || item.sabado == 1 || item.domingo == 1"
		            	type="button" 
		            	@click="bloque =  'ju3', grupo = item.iderp, asistencia = item.ju3, dialogAsistencia = true"
		            >
			            <v-chip color="green" dark small v-if="item.ju3_as && item.ju3_as.substr( item.ju3_as.length - 1,1 ) == 1 ">Asistió</v-chip>
			            <v-tooltip bottom>
							      <template v-slot:activator="{ on, attrs }">
	            				<v-chip 
							          v-bind="attrs"
							          v-on="on"
	            					color="red" 
	            					dark 
	            					small 
	            					v-if="item.ju3_as && item.ju3_as.substr( item.ju3_as.length - 1,1 ) == 2 "
	            				>
	            					No Asistió
	            				</v-chip>
							      </template>
							      <span>{{ item.ju3_as }}</span>
							    </v-tooltip>
		          	</span>
		          	<div v-if="( item.jueves == 1 || item.sabado == 1 || item.domingo == 1 )&& item.lju3 ">
                  <v-chip small rounded color="pink" dark v-for="(leccion, i ) in item.lju3.split(',')" :key="i" class="mt-1">
                    {{ leccion }}
                  </v-chip>
                </div>
                <div v-if="( item.jueves == 1 || item.sabado == 1 || item.domingo == 1 )&& item.ju3_bitacora ">
                  <v-chip small rounded color="lime accent-4" dark class="mt-1 black--text" @click="verBitacora('ju3', item.ju3, item.idrol_clases)">
                    Bitacora
                  </v-chip>
                </div>
		          </template>

		          <template v-slot:item.vi3_n="{ item }" class="text-subtitle-1">
		            <span @click="item.vi3_bloqueo ? '' : abrirDialogMaestro( item, 'vi3' )" type="button" class="text-caption" v-if="item.viernes == 1 || item.sabado == 1 || item.domingo == 1" :class="item.vi3_falta ? 'red darken-2 white--text' : '' " 
		            	:id="!item.grupo.match('ONLINE') && item.vi3_fecha <= fecha_hoy && !item.vi3_hora_asistencia ? 'yellow' : '' ">
		            	<v-icon :color="item.vi3_confirmacion == 1 ? 'green' :  item.vi3_confirmacion == 2 ? 'red' : 'grey'">mdi-circle-medium</v-icon>
		            	{{ item.vi3_n ? item.vi3_n : 'SIN TEACHER' }}
		            </span>

		            <!-- ASISTENCIA -->
		            {{ item.vi3_hora_asistencia ? item.vi3_hora_asistencia : '' }}
		          	<span type="button" v-if="item.viernes == 0 && item.sabado == 0 && item.domingo == 0">
		            	<v-btn tile color="black"></v-btn>
		            </span>
		          	<span 
		            	v-if="item.viernes == 1 || item.sabado == 1 || item.domingo == 1" 
		            	type="button" 
		            	@click="bloque =  'vi3', grupo = item.iderp, asistencia = item.vi3, dialogAsistencia = true"
		            >
			            <v-chip color="green" dark small v-if="item.vi3_as && item.vi3_as.substr( item.vi3_as.length - 1,1 ) == 1 ">Asistió</v-chip>
			            <v-tooltip bottom>
							      <template v-slot:activator="{ on, attrs }">
	            				<v-chip 
							          v-bind="attrs"
							          v-on="on"
	            					color="red" 
	            					dark 
	            					small 
	            					v-if="item.vi3_as && item.vi3_as.substr( item.vi3_as.length - 1,1 ) == 2 "
	            				>
	            					No Asistió
	            				</v-chip>
							      </template>
							      <span>{{ item.vi3_as }}</span>
							    </v-tooltip>
		          	</span>
		          	<div v-if="( item.viernes == 1 || item.sabado == 1 || item.domingo == 1 )&& item.lvi3 ">
                  <v-chip small rounded color="pink" dark v-for="(leccion, i ) in item.lvi3.split(',')" :key="i" class="mt-1">
                    {{ leccion }}
                  </v-chip>
                </div>
                <div v-if="( item.viernes == 1 || item.sabado == 1 || item.domingo == 1 )&& item.vi3_bitacora ">
                  <v-chip small rounded color="lime accent-4" dark class="mt-1 black--text" @click="verBitacora('vi3', item.vi3, item.idrol_clases)">
                    Bitacora
                  </v-chip>
                </div>
		          </template>

		          <!-- Editar Bloque 4 -->
		          <template v-slot:item.lu4_n="{ item }" class="text-subtitle-1">
		            <span @click="item.lu4_bloqueo ? '' : abrirDialogMaestro( item, 'lu4' )" type="button" class="text-caption" v-if="item.lunes == 1 || item.sabado == 1 || item.domingo == 1" :class="item.lu4_falta ? 'red darken-2 white--text' : '' " 
		            	:id="!item.grupo.match('ONLINE') && item.lu4_fecha <= fecha_hoy && !item.lu4_hora_asistencia ? 'yellow' : '' ">
		            	<v-icon :color="item.lu4_confirmacion == 1 ? 'green' :  item.lu4_confirmacion == 2 ? 'red' : 'grey'">mdi-circle-medium</v-icon>
		            	{{ item.lu4_n ? item.lu4_n : 'SIN TEACHER' }}
		            </span>

		            <!-- ASISTENCIA -->
		            {{ item.lu4_hora_asistencia ? item.lu4_hora_asistencia : '' }}
		          	<span type="button" v-if="item.lunes == 0 && item.sabado == 0 && item.domingo == 0">
		            	<v-btn tile color="black"></v-btn>
		            </span>
		          	<span 
		            	v-if="item.lunes == 1 || item.sabado == 1 || item.domingo == 1"
		            	type="button" 
		            	@click="bloque =  'lu4', grupo = item.iderp, asistencia = item.lu4, dialogAsistencia = true"
		            >
		            	<v-chip color="green" dark small v-if="item.lu4_as && item.lu4_as.substr( item.lu4_as.length - 1,1 ) == 1 ">Asistió</v-chip>
			            <v-tooltip bottom>
							      <template v-slot:activator="{ on, attrs }">
	            				<v-chip 
							          v-bind="attrs"
							          v-on="on"
	            					color="red" 
	            					dark 
	            					small 
	            					v-if="item.lu4_as && item.lu4_as.substr( item.lu4_as.length - 1,1 ) == 2 "
	            				>
	            					No Asistió
	            				</v-chip>
							      </template>
							      <span>{{ item.lu4_as }}</span>
							    </v-tooltip>
		            </span>
		            <div v-if="( item.lunes == 1 || item.sabado == 1 || item.domingo == 1 )&& item.llu4 ">
                  <v-chip small rounded color="pink" dark v-for="(leccion, i ) in item.llu4.split(',')" :key="i" class="mt-1">
                    {{ leccion }}
                  </v-chip>
                </div>
                <div v-if="( item.lunes == 1 || item.sabado == 1 || item.domingo == 1 )&& item.lu4_bitacora ">
                  <v-chip small rounded color="lime accent-4" dark class="mt-1 black--text" @click="verBitacora('lu4', item.lu4, item.idrol_clases)">
                    Bitacora
                  </v-chip>
                </div>
		          </template>

		          <template v-slot:item.ma4_n="{ item }" class="text-subtitle-1">
		            <span @click="item.ma4_bloqueo ? '' : abrirDialogMaestro( item, 'ma4' )" type="button" class="text-caption" v-if="item.martes == 1 || item.sabado == 1 || item.domingo == 1" :class="item.ma4_falta ? 'red darken-2 white--text' : '' " 
		            	:id="!item.grupo.match('ONLINE') && item.ma4_fecha <= fecha_hoy && !item.ma4_hora_asistencia ? 'yellow' : '' ">
		            	<v-icon :color="item.ma4_confirmacion == 1 ? 'green' :  item.ma4_confirmacion == 2 ? 'red' : 'grey'">mdi-circle-medium</v-icon>
		            	{{ item.ma4_n ? item.ma4_n : 'SIN TEACHER' }}
		            </span>

		            <!-- ASISTENCIA -->
		            {{ item.ma4_hora_asistencia ? item.ma4_hora_asistencia : '' }}
		          	<span type="button" v-if="item.martes == 0 && item.sabado == 0 && item.domingo == 0">
		            	<v-btn tile color="black"></v-btn>
		            </span>
		          	<span 
		            	v-if="item.martes == 1 || item.sabado == 1 || item.domingo == 1"
		            	type="button" 
		            	@click="bloque =  'ma4', grupo = item.iderp, asistencia = item.ma4, dialogAsistencia = true"
		            >
			            <v-chip color="green" dark small v-if="item.ma4_as && item.ma4_as.substr( item.ma4_as.length - 1,1 ) == 1 ">Asistió</v-chip>
			            <v-tooltip bottom>
							      <template v-slot:activator="{ on, attrs }">
	            				<v-chip 
							          v-bind="attrs"
							          v-on="on"
	            					color="red" 
	            					dark 
	            					small 
	            					v-if="item.ma4_as && item.ma4_as.substr( item.ma4_as.length - 1,1 ) == 2 "
	            				>
	            					No Asistió
	            				</v-chip>
							      </template>
							      <span>{{ item.ma4_as }}</span>
							    </v-tooltip>
		          	</span>
		          	<div v-if="( item.martes == 1 || item.sabado == 1 || item.domingo == 1 )&& item.lma4 ">
                  <v-chip small rounded color="pink" dark v-for="(leccion, i ) in item.lma4.split(',')" :key="i" class="mt-1">
                    {{ leccion }}
                  </v-chip>
                </div>
                <div v-if="( item.martes == 1 || item.sabado == 1 || item.domingo == 1 )&& item.ma4_bitacora ">
                  <v-chip small rounded color="lime accent-4" dark class="mt-1 black--text" @click="verBitacora('ma4', item.ma4, item.idrol_clases)">
                    Bitacora
                  </v-chip>
                </div>
		          </template>

		          <template v-slot:item.mi4_n="{ item }" class="text-subtitle-1">
		            <span @click="item.mi4_bloqueo ? '' : abrirDialogMaestro( item, 'mi4' )" type="button" class="text-caption" v-if="item.miercoles == 1 || item.sabado == 1 || item.domingo == 1" :class="item.mi4_falta ? 'red darken-2 white--text' : '' " 
		            	:id="!item.grupo.match('ONLINE') && item.mi4_fecha <= fecha_hoy && !item.mi4_hora_asistencia ? 'yellow' : '' ">
		            	<v-icon :color="item.mi4_confirmacion == 1 ? 'green' :  item.mi4_confirmacion == 2 ? 'red' : 'grey'">mdi-circle-medium</v-icon>
		            	{{ item.mi4_n ? item.mi4_n : 'SIN TEACHER' }}
		            </span>

		            <!-- ASISTENCIA -->
		            {{ item.mi4_hora_asistencia ? item.mi4_hora_asistencia : '' }}
		          	<span type="button" v-if="item.miercoles == 0 && item.sabado == 0 && item.domingo == 0">
		            	<v-btn tile color="black"></v-btn>
		            </span>
		          	<span 
		            	v-if="item.miercoles == 1 || item.sabado == 1 || item.domingo == 1"
		            	type="button" 
		            	@click="bloque =  'mi4', grupo = item.iderp, asistencia = item.mi4, dialogAsistencia = true"
		            >
			            <v-chip color="green" dark small v-if="item.mi4_as && item.mi4_as.substr( item.mi4_as.length - 1,1 ) == 1 ">Asistió</v-chip>
			            <v-tooltip bottom>
							      <template v-slot:activator="{ on, attrs }">
	            				<v-chip 
							          v-bind="attrs"
							          v-on="on"
	            					color="red" 
	            					dark 
	            					small 
	            					v-if="item.mi4_as && item.mi4_as.substr( item.mi4_as.length - 1,1 ) == 2 "
	            				>
	            					No Asistió
	            				</v-chip>
							      </template>
							      <span>{{ item.mi4_as }}</span>
							    </v-tooltip>
		          	</span>
		          	<div v-if="( item.miercoles == 1 || item.sabado == 1 || item.domingo == 1 )&& item.lmi4 ">
                  <v-chip small rounded color="pink" dark v-for="(leccion, i ) in item.lmi4.split(',')" :key="i" class="mt-1">
                    {{ leccion }}
                  </v-chip>
                </div>
                <div v-if="( item.miercoles == 1 || item.sabado == 1 || item.domingo == 1 )&& item.mi4_bitacora ">
                  <v-chip small rounded color="lime accent-4" dark class="mt-1 black--text" @click="verBitacora('mi4', item.mi4, item.idrol_clases)">
                    Bitacora
                  </v-chip>
                </div>
		          </template>

		          <template v-slot:item.ju4_n="{ item }" class="text-subtitle-1">
		            <span @click="item.ju4_bloqueo ? '' : abrirDialogMaestro( item, 'ju4' )" type="button" class="text-caption" v-if="item.jueves == 1 || item.sabado == 1 || item.domingo == 1" :class="item.ju4_falta ? 'red darken-2 white--text' : '' " 
		            	:id="!item.grupo.match('ONLINE') && item.ju4_fecha <= fecha_hoy && !item.ju4_hora_asistencia ? 'yellow' : '' ">
		            	<v-icon :color="item.ju4_confirmacion == 1 ? 'green' :  item.ju4_confirmacion == 2 ? 'red' : 'grey'">mdi-circle-medium</v-icon>
		            	{{ item.ju4_n ? item.ju4_n : 'SIN TEACHER' }}
		            </span>

		            <!-- ASISTENCIA -->
		            {{ item.ju4_hora_asistencia ? item.ju4_hora_asistencia : '' }}
		          	<span type="button" v-if="item.jueves == 0 && item.sabado == 0 && item.domingo == 0">
		            	<v-btn tile color="black"></v-btn>
		            </span>
		          	<span 
		            	v-if="item.jueves == 1 || item.sabado == 1 || item.domingo == 1"
		            	type="button" 
		            	@click="bloque =  'ju4', grupo = item.iderp, asistencia = item.ju4, dialogAsistencia = true"
		            >
			            <v-chip color="green" dark small v-if="item.ju4_as && item.ju4_as.substr( item.ju4_as.length - 1,1 ) == 1 ">Asistió</v-chip>
			            <v-tooltip bottom>
							      <template v-slot:activator="{ on, attrs }">
	            				<v-chip 
							          v-bind="attrs"
							          v-on="on"
	            					color="red" 
	            					dark 
	            					small 
	            					v-if="item.ju4_as && item.ju4_as.substr( item.ju4_as.length - 1,1 ) == 2 "
	            				>
	            					No Asistió
	            				</v-chip>
							      </template>
							      <span>{{ item.ju4_as }}</span>
							    </v-tooltip>
		          	</span>
		          	<div v-if="( item.jueves == 1 || item.sabado == 1 || item.domingo == 1 )&& item.lju4 ">
                  <v-chip small rounded color="pink" dark v-for="(leccion, i ) in item.lju4.split(',')" :key="i" class="mt-1">
                    {{ leccion }}
                  </v-chip>
                </div>
                <div v-if="( item.jueves == 1 || item.sabado == 1 || item.domingo == 1 )&& item.ju4_bitacora ">
                  <v-chip small rounded color="lime accent-4" dark class="mt-1 black--text" @click="verBitacora('ju4', item.ju4, item.idrol_clases)">
                    Bitacora
                  </v-chip>
                </div>
		          </template>

		          <template v-slot:item.vi4_n="{ item }" class="text-subtitle-1">
		            <span @click="item.vi4_bloqueo ? '' : abrirDialogMaestro( item, 'vi4' )" type="button" class="text-caption" v-if="item.viernes == 1 || item.sabado == 1 || item.domingo == 1" :class="item.vi4_falta ? 'red darken-2 white--text' : '' " 
		            	:id="!item.grupo.match('ONLINE') && item.vi4_fecha <= fecha_hoy && !item.vi4_hora_asistencia ? 'yellow' : '' ">
		            	<v-icon :color="item.vi4_confirmacion == 1 ? 'green' :  item.vi4_confirmacion == 2 ? 'red' : 'grey'">mdi-circle-medium</v-icon>
		            	{{ item.vi4_n ? item.vi4_n : 'SIN TEACHER' }}
		            </span>

		            <!-- ASISTENCIA -->
		            {{ item.vi4_hora_asistencia ? item.vi4_hora_asistencia : '' }}
		          	<span type="button" v-if="item.viernes == 0 && item.sabado == 0 && item.domingo == 0">
		            	<v-btn tile color="black"></v-btn>
		            </span>
		          	<span 
		            	v-if="item.viernes == 1 || item.sabado == 1 || item.domingo == 1" 
		            	type="button" 
		            	@click="bloque =  'vi4', grupo = item.iderp, asistencia = item.vi4, dialogAsistencia = true"
		            >
			            <v-chip color="green" dark small v-if="item.vi4_as && item.vi4_as.substr( item.vi4_as.length - 1,1 ) == 1 ">Asistió</v-chip>
			            <v-tooltip bottom>
							      <template v-slot:activator="{ on, attrs }">
	            				<v-chip 
							          v-bind="attrs"
							          v-on="on"
	            					color="red" 
	            					dark 
	            					small 
	            					v-if="item.vi4_as && item.vi4_as.substr( item.vi4_as.length - 1,1 ) == 2 "
	            				>
	            					No Asistió
	            				</v-chip>
							      </template>
							      <span>{{ item.vi4_as }}</span>
							    </v-tooltip>
		          	</span>
		          	<div v-if="( item.viernes == 1 || item.sabado == 1 || item.domingo == 1 )&& item.lvi4 ">
                  <v-chip small rounded color="pink" dark v-for="(leccion, i ) in item.lvi4.split(',')" :key="i" class="mt-1">
                    {{ leccion }}
                  </v-chip>
                </div>
                <div v-if="( item.viernes == 1 || item.sabado == 1 || item.domingo == 1 )&& item.vi4_bitacora ">
                  <v-chip small rounded color="lime accent-4" dark class="mt-1 black--text" @click="verBitacora('vi4', item.vi4, item.idrol_clases)">
                    Bitacora
                  </v-chip>
                </div>
		          </template>

		        </v-data-table>
      	  </v-card-text>
      	</v-card>

      </v-col>
    </v-row>

    <!-- Dialogo para agregar un maestro al grupo -->
    <v-dialog
      v-model="dialogMaestro"
      max-width="500px"
      transition="dialog-transition"
    >
      <v-card>
        <v-card-title primary-title>
          Agregar el teacher
        </v-card-title>
        <v-card-text>

        	<v-alert
			      type="error"
			    >
			      <v-row align="center"  class="text-center">
			        <span class="text-h4 text-center" align="center"> SEMANA {{ selectedHeaders }}</span>
			      </v-row>
			    </v-alert>

        	<div v-if="teacher && teacher[`${bloque}_falta`]" class="black--text">
        		<v-alert
				      border="top"
				      dark
				      type="error"
				    >
	        		ALERTAA: TEACHER NO ASISITIRÁ A CLASE
	        		<br/>
	        		{{ teacher[`${bloque}_falta`].nombre_completo }}
	        		<br/>
	        		{{ teacher[`${bloque}_falta`].motivo }}
				    </v-alert>
        	</div>

        	<v-checkbox label="Sin Teacher" v-model="opSinTeacher" :value="1"></v-checkbox>

          <v-autocomplete
            filled
            dense
            clearable
            v-model="teacherReemplazo"
            :items="teachers"
            label="Selecciona el teacher"
            item-text="nombre_completo"
            item-value="id_usuario"
            class="mt-4"
            hide-details
            return-object
          >
          </v-autocomplete>

          <v-radio-group
			      v-model="opAsignacion"
			      row
			      hide-details
			    >
			      <v-radio
			        label="Permanente"
			        :value="1"
			      ></v-radio>
			      <v-radio
			        label="Temporal"
			        :value="2"
			      ></v-radio>
			    </v-radio-group>


			    <v-checkbox 
			    	label="Pado Doble" 
			    	v-model="pagoDoble" 
			    	value="1"
			    ></v-checkbox>
        </v-card-text>
        <v-card-actions>
        	<v-btn 
        		color="black"
        		small 
        		rounded 
        		@click="dialogMaestro = false, teacherReemplazo = null" 
        		dark
        		tile
        	>
        		<v-icon small left>mdi-cancel</v-icon>
        		Cancelar
        	</v-btn>

          <v-spacer></v-spacer>

        	<v-btn 
        		color="green" 
        		dark
        		small 
        		rounded 
        		@click="guardarMaestro( )"
        		tile
        	>
        		<v-icon small left>mdi-content-save</v-icon>
        		Guardar
        	</v-btn>
        
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="dialogZoom"
      max-width="400"
      v-if="codigo"
    >
      <v-card>
        <v-card-title class="text-h5">
          Codigo Clase
        </v-card-title>
        <v-card-text>
	  			<v-textarea
	  				v-model="codigo.codigos"
	  				rows="3"
	  				auto-grow
	  				label="Agregar acceso al zoom"
	  				filled
	  				hide-details
	  				readonly
	  			/>
        </v-card-text>
        <v-card-actions>
        	<v-btn
            color="black"
            tile
            dark
            small
            @click="dialogZoom = false"
          >
          	<v-icon small left>mdi-cancel</v-icon>
            Cancelar
          </v-btn>

          <v-btn
            color="orange"
            tile
            dark
            small
            @click="copiarPortapapeles( codigo.codigos )"
          >
          	<v-icon small left>mdi-content-copy</v-icon>
            Copiar
          </v-btn>

          <v-btn
            color="green"
            tile
            dark
            small
            @click="abrirZoom( codigo.codigos )"
          >
          	<v-icon small left>mdi-lock-open</v-icon>
            Abrir Zoom
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- dialog para ver grupos sin teacher en ese día -->
    <v-dialog
      v-model="dialogSinTeacher"
      max-width="1000px"
      transition="dialog-transition"
    >
      <v-card>
        <v-card-title primary-title>
          Grupos sin Teacher
        </v-card-title>
        <v-card-text>
        	<v-row class="mb-4">
        		<v-col cols="12" md="6" lg="3">
		        	<v-select
		        		:items="['lunes-1','martes-1','miercoles-1','jueves-1','viernes-1','sabado-1','domingo-1','lunes-2','martes-2','miercoles-2','jueves-2','viernes-2','sabado-2','domingo-2','lunes-3','martes-3','miercoles-3','jueves-3','viernes-3','sabado-3','domingo-3','lunes-4','martes-4','miercoles-4','jueves-4','viernes-4','sabado-4','domingo-4']"
			        	label="Día"
		        	  dense
		        	  hide-details
		        	  v-model="diaSeleccionado"
		        	  clearable
		        	  single-line
		        	  filled
		        	></v-select>
        		</v-col>
        	</v-row>
          <!-- Tabla con toda la información -->
	        <v-data-table
	          :headers="headerGrupos"
	          :items="filterGruposSinTeacher"
	          class="elevation-0"
	          height="500"
	          dense
	          fixed-header
	          :search="search2"
	          multi-sort
	          :loading="cargar"
	        > 
	        </v-data-table>
        </v-card-text>
        <v-card-actions>
        	<v-btn 
        		color="black"
        		small 
        		rounded 
        		@click="dialogSinTeacher = false" 
        		dark
        		tile
        	>
        		<v-icon small left>mdi-cancel</v-icon>
        		Cancelar
        	</v-btn>

          <v-spacer></v-spacer>

        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="dialogDuplicado"
      max-width="1000px"
      transition="dialog-transition"
    >
      <v-card>
        <v-card-title primary-title>
          ERRORES EN EL ROL
        </v-card-title>
        <v-card-text>
          <!-- Tabla con toda la información -->
	        <v-data-table
	          :headers="headersErrores"
	          :items="datosDuplicados"
	          class="elevation-0"
	          dense
	          fixed-header
	        > 
	        </v-data-table>
        </v-card-text>
        <v-card-actions>
        	<v-btn 
        		color="black"
        		small 
        		rounded 
        		@click="dialogDuplicado = false" 
        		dark
        		tile
        	>
        		<v-icon small left>mdi-cancel</v-icon>
        		Cancelar
        	</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Dialogo para agregar un maestro al grupo -->
    <v-dialog
      v-model="dialogBitacora"
      max-width="500px"
      transition="dialog-transition"
    >
      <v-card>
        <v-card-title primary-title>
          Bitacora
        </v-card-title>
        <v-card-text>
          <div v-if="bitacora">
          	<b>Fecha creación:</b> {{ bitacora.fecha }}
          	<br/> 
          	<b>Teacher:</b> {{ bitacora.teacher }} 
          	<br/> 
          	<b>Enc / Rec:</b> {{ bitacora.usuario }} 

          </div>
          <br/>

          <!-- Aquí van las preguntas de la bitacora -->
          <span v-for="(pregunta, i) in preguntas" :key="i">
          	<v-icon 
          		v-text="pregunta.correcto ? 'mdi-check-bold' : 'mdi-close' " 
          		:color="pregunta.correcto ? 'green' : 'red' " 
          		left
          	></v-icon>
          	{{ pregunta.pregunta }}
          	<br/>
          	<v-chip v-if="pregunta.aceptado == 0" color="grey"  small dark>Pendiente</v-chip>
          	<v-chip v-if="pregunta.aceptado == 1" color="green" small dark>Aceptado</v-chip>
          	<v-chip v-if="pregunta.aceptado == 2" color="red"   small dark>Rechazado</v-chip>
          	<br/>
          	<v-textarea
          		rows="2"
          		auto-grow
          		filled
          		v-model="pregunta.motivo"
          		readonly
          		v-if="pregunta.aceptado == 2"
          	/>
          	<br/>
          </span>
        </v-card-text>
        <v-card-actions>
        	<v-btn 
        		color="black"
        		small 
        		rounded 
        		@click="dialogBitacora = false" 
        		dark
        		tile
        	>
        		<v-icon small left>mdi-cancel</v-icon>
        		Cancelar
        	</v-btn>

          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <dialogNoRolados
    	v-if="dialogNoRolados.estatus"
    	:dialogNoRolados="dialogNoRolados"
    	:gruposRoles="gruposRoles"
    	:teachers="teachers"
    />

    <dialogBonoTeachers
    	v-if="dialogBonos.estatus"
    	:dialogBonos="dialogBonos"
    	:gruposRoles="gruposRoles"
    	:teachers="teachers"
    />

    <dialogRolados
    	v-if="dialogRolados.estatus"
    	:dialogRolados="dialogRolados"
    	:gruposRoles="gruposRoles"
    	:teachers="teachers"
    />

    <dialogConfirmados
    	v-if="dialogConfirmados.estatus"
    	:dialogConfirmados="dialogConfirmados"
    	:gruposRoles="gruposRoles"
    	:teachers="teachers"
    />

    <dialogMaps
    	v-if="dialogMaps.estatus"
    	:dialogMaps="dialogMaps"
    	:codigo="codigo"
    />

    <dialogHorasDobles
      v-if="dialogDobles.estatus"
      :dialogDobles="dialogDobles"
      :rolReemplazo="rolReemplazo"
      :gruposRoles="gruposRoles"
			:pagos="pagos"
			:rolRegistrado="rolRegistrado"
			:horasDobles="horasDobles"
			@initialize="consultar"
    />

    <dialogComparativa
      v-if="dialogComparativa.estatus"
      :dialogComparativa="dialogComparativa"
      :gruposRoles="gruposRoles"
			@initialize="consultar"
    />

    <nominaSabatina
    	v-if="dialogNominaSabatina.estatus"
      :dialogNominaSabatina="dialogNominaSabatina"
      :gruposRoles="gruposRoles"
			@initialize="consultar"
			:selectedHeaders="selectedHeaders"
			:pagos="pagos"
    />

    <nominaQuincena
    	v-if="dialogNominaQuincena.estatus"
      :dialogNominaQuincena="dialogNominaQuincena"
      :gruposRoles="gruposRoles"
			@initialize="consultar"
			:selectedHeaders="selectedHeaders"
			:pagos="pagos"
			:horasDobles="horasDobles"
			:gruposRoles2="gruposRoles2"
    />

    <!-- Dialog de carga -->
    <Alerta               v-if="respuestaAlerta"             :parametros="parametros"/>
    <carga                v-if="cargar"/>

	</v-container>
</template>
<script>
import axios from 'axios';
	import { mapGetters } from 'vuex'
	// Componentes
  import Alerta                from '@/components/alertas/Alerta.vue'
  import carga                 from '@/components/alertas/carga.vue';

  import botonesRoles          from '@/components/roles/botonesRoles.vue';
  import botonesLider          from '@/components/roles/botonesLider.vue';
  import dialogComparativa     from '@/components/roles/dialogComparativa.vue';
  import dialogNoRolados       from '@/components/roles/dialogNoRolados.vue';
  import dialogRolados         from '@/components/roles/dialogRolados.vue';
  import dialogConfirmados     from '@/components/roles/dialogConfirmados.vue';
  import dialogMaps            from '@/components/roles/dialogMaps.vue';
  import dialogHorasDobles     from '@/components/roles/dialogHorasDobles.vue';
  import dialogBonoTeachers    from '@/components/roles/dialogBonoTeachers.vue';
  import nominaSabatina        from '@/components/roles/nominaSabatina.vue';
  import nominaQuincena        from '@/components/roles/nominaQuincena.vue';

  import validarErrores        from '@/mixins/validarErrores'
  import funcionesExcel        from '@/mixins/funcionesExcel'
  import filtrosRolTeachers    from '@/mixins/filtrosRolTeachers'

	var moment = require('moment');
  moment.locale(); 

  export default {

  	components:{
      Alerta,
      carga,
      botonesRoles,
      dialogNoRolados,
      dialogRolados,
      dialogConfirmados,
      dialogMaps,
      dialogHorasDobles,
      dialogBonoTeachers,
      dialogComparativa,
      nominaSabatina,
      nominaQuincena,
      botonesLider
    },

    mixins: [ validarErrores, funcionesExcel, filtrosRolTeachers ],

    data: () => ({
    	// Alertas
    	parametros:{
        dialogError: false,
        mensaje: '',
        color: ''
      },

      respuestaAlerta:false,
      loader: true,
      cargar: false,

      grupoRolesPerfecto:[],
      search:'',
      gruposRoles: [],
      ciclos:[],
      ciclo:null,

      headerGrupos: [
	      { text: 'Grupo'        , value: 'grupo'       },
	      { text: 'Inicio'       , value: 'hora_inicio' },
	      { text: 'Final'        , value: 'hora_fin'    },
	      { text: 'Alumnos'      , value: 'cant_alumnos'},
	    ],

	    headersErrores: [
	      { text: 'Teacher'     , value: 'teacher'  },
	      { text: 'Descripción' , value: 'describe' },
	      { text: 'Día'         , value: 'dia'      },
	      { text: 'Bloque'      , value: 'bloque'   },
	    ],

      teacher: null,
      dialogReemplazo: false,
      teachers:[],
      teacherReemplazo: null,
      bloque: '',

      // Filtros avanzados
      niveles:[],
      nivel: null,

      planteles: [],
      plantel: null,
      plantelRol: null,

      horarios:[],
      horario: null,

      modalidades:[],
      modalidad:null,

      teachersFiltros:[],
			teacherFilter: null,

			dialogMaestro: false,

			escuela: 1,
			opAsignacion: null,

			dialogRolados: {
				estatus: false
			},
			dialogNoRolados: {
				estatus: false
			},
			dialogConfirmados:{
				estatus: false,
			},

	    diaSeleccionado: null,

	    search2: "",

	    codigo: null,
			dialogZoom: false,
			horario: null,
			dialogSinTeacher: false,
			opSinTeacher: 0,
			pagos:[],
			dialogMaps : {
				estatus:false
			},

			ultimaActualizacion: null,
			salones:[],
			dialogBitacora: false,
			preguntas:[],
			bitacora: null,

			rolReemplazo: [],

			dialogDobles: {
				estatus: false,
			},

			dialogBonos:{
				estatus: false
			},

			rolRegistrado: [],
			horasDobles:[],

		 	fecha_hoy: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),

		 	pagoDoble: 0,

		 	dialogComparativa:{
		 		estatus: false,
		 	},

		 	dialogNominaSabatina: {
		 		estatus: false
		 	},

		 	dialogNominaQuincena: {
		 		estatus: false
		 	},

		 	gruposRoles2: [],

    }),

    computed:{
      ...mapGetters('login',['getdatosUsuario']),
    },

    watch: {
      // Consultar cuando haya un cambio en el ciclo y este sea un ciclo seleccionado y no un ciclo vacio
      ciclo () {
        if(this.ciclo){
          this.consultar()
          // this.getNominaTeacher2( )
        }
      },

      plantelRol( ){
      	if(this.plantelRol){
      		this.getRolados()
      	}
      }
    },

    async created () {
      await this.initialize()
      await this.getTeachersActivos()
    	await this.getPlanteles()
    	await this.getSalones()
    },

    methods: {
      // Funcion para cargar datos iniciales
      initialize () {
      	this.cargar = true
        // Vaciar los ciclos
        this.ciclos = []
        // Obtener todos los ciclos
        this.$http.get('roles.ciclos.all').then(response=>{
          // hacer un ciclo ya que los ciclos vienen varios como inscripciones y todo eso
          // y solo queremos los ciclos que en su nombre dicen ciclo 
          for(const i in response.data){
            //  si es diferente a FE
            if (!(response.data[i].ciclo.search('FE') != -1)) {
              // Y es igual a FE, se agrega a ciclos
              // por qué es diferente a FE? por que es por estetica, mostrar solo los ciclos normales, ya que los ciclos tienen ligado el ciclo FE
              if(response.data[i].ciclo.search('CICLO') != -1){
                this.ciclos.push(response.data[i])
              }
            }
          }

      		this.cargar = false

        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      getPlanteles () {
      	this.cargar = true
        this.planteles = []
        return this.$http.get('planteles.oficiales').then(response=>{
        	console.log( response.data )
        	this.planteles = response.data
        	this.cargar      = false
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      getSalones(){
    		this.cargar = true
        this.salones = []
        return this.$http.get('salones.list').then(response=>{
        	this.salones = response.data
        	this.cargar      = false
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
    	},

      getTeachersActivos( ){
      	this.cargar = true
      	this.teachers = []
        return this.$http.get('academico.teachers.activos').then(response=>{
          this.teachers = response.data
          this.cargar = false
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      consultar () {
      	if( !this.ciclo ){
      		return this.validarErrorDirecto('Selecciona un ciclo por favor')
      	}

      	let planteles = []

      	// XCARET
      	if( [886].includes( this.getdatosUsuario.iderp ) ){
      	  planteles = [14,12,16,9]
      	}

      	// MOISES LARA
      	if( [1593].includes( this.getdatosUsuario.iderp ) ){
      	  planteles = [8,10,7,11]
      	}

      	// Carlos Eduardo de Leon
      	if( [1177].includes( this.getdatosUsuario.iderp ) ){
      	  planteles = [1,23,22,24,29]
      	}

      	// JESUS OSWALDO MONTALVO LUNA
      	if( [1336].includes( this.getdatosUsuario.iderp ) ){
      	  planteles = [5,2,3]
      	}

      	this.gruposRoles = []
      	this.cargar      = true
        this.$http.post('rol.clases', this.ciclo ).then(response=>{
          this.gruposRoles         = response.data.respuesta
          this.pagos               = response.data.teachers
          this.ultimaActualizacion = response.data.ultimaActualizacion
          this.rolReemplazo        = response.data.rolReemplazo
          this.rolRegistrado       = response.data.rolRegistrado
          this.horasDobles         = response.data.horasDobles

          if( [886,1593,1177,1336].includes( this.getdatosUsuario.iderp ) ){
          	this.gruposRoles = this.gruposRoles.filter( el => planteles.includes( el.id_plantel ) )
          }

          this.niveles     = response.data.respuesta.map((registro) => { return registro.id_nivel }).sort()
          
          this.horarios    = response.data.respuesta.map((registro) => { return registro.hora_inicio }).sort()

          // Obtener los nombres de tooodos los teachers
          let tlu1 = response.data.respuesta.filter( el => { return el.lu1_n }).map((registro) => { return registro.lu1_n })
          let tma1 = response.data.respuesta.filter( el => { return el.ma1_n }).map((registro) => { return registro.ma1_n })
          let tmi1 = response.data.respuesta.filter( el => { return el.mi1_n }).map((registro) => { return registro.mi1_n })
          let tju1 = response.data.respuesta.filter( el => { return el.ju1_n }).map((registro) => { return registro.ju1_n })
          let tvi1 = response.data.respuesta.filter( el => { return el.vi1_n }).map((registro) => { return registro.vi1_n })

          let tlu2 = response.data.respuesta.filter( el => { return el.lu2_n }).map((registro) => { return registro.lu2_n })
          let tma2 = response.data.respuesta.filter( el => { return el.ma2_n }).map((registro) => { return registro.ma2_n })
          let tmi2 = response.data.respuesta.filter( el => { return el.mi2_n }).map((registro) => { return registro.mi2_n })
          let tju2 = response.data.respuesta.filter( el => { return el.ju2_n }).map((registro) => { return registro.ju2_n })
          let tvi2 = response.data.respuesta.filter( el => { return el.vi2_n }).map((registro) => { return registro.vi2_n })

          let tlu3 = response.data.respuesta.filter( el => { return el.lu3_n }).map((registro) => { return registro.lu3_n })
          let tma3 = response.data.respuesta.filter( el => { return el.ma3_n }).map((registro) => { return registro.ma3_n })
          let tmi3 = response.data.respuesta.filter( el => { return el.mi3_n }).map((registro) => { return registro.mi3_n })
          let tju3 = response.data.respuesta.filter( el => { return el.ju3_n }).map((registro) => { return registro.ju3_n })
          let tvi3 = response.data.respuesta.filter( el => { return el.vi3_n }).map((registro) => { return registro.vi3_n })

          let tlu4 = response.data.respuesta.filter( el => { return el.lu4_n }).map((registro) => { return registro.lu4_n })
          let tma4 = response.data.respuesta.filter( el => { return el.ma4_n }).map((registro) => { return registro.ma4_n })
          let tmi4 = response.data.respuesta.filter( el => { return el.mi4_n }).map((registro) => { return registro.mi4_n })
          let tju4 = response.data.respuesta.filter( el => { return el.ju4_n }).map((registro) => { return registro.ju4_n })
          let tvi4 = response.data.respuesta.filter( el => { return el.vi4_n }).map((registro) => { return registro.vi4_n })

          this.teachersFiltros = tlu1.concat(tma1).concat(tmi1).concat(tju1).concat(tvi1).concat(tlu2).concat(tma2).concat(tmi2).concat(tju2).concat(tvi2).concat(tlu3).concat(tma3).concat(tmi3).concat(tju3).concat(tvi3).concat(tlu4).concat(tma4).concat(tmi4).concat(tju4).concat(tvi4).sort()

          // this.cargar      = false
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      getNominaTeacher2( ){
				this.gruposRoles2 = []
				this.cargar      = true

				return this.$http.post('rol.clases.nomina2', this.ciclo ).then(response=>{
					this.gruposRoles2         = response.data.respuesta
					// this.pagos                = response.data.teachers
					//   this.ultimaActualizacion = response.data.ultimaActualizacion
		    	//   this.rolReemplazo        = response.data.rolReemplazo
		    	//   this.rolRegistrado       = response.data.rolRegistrado
				}).catch( error =>{
					this.validarError( error.response.data.message )
				}).finally( () => { this.cargar = false })
			},

      cargarBloque(){
      	
      	if( !this.ciclo ){
      		return this.validarErrorDirecto('Selecciona un ciclo por favor')
      	}

      	this.cargar      = true
      
        this.$http.post('rol.clases.add', this.gruposRoles ).then(response=>{
          this.consultar()
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      abrirDialogMaestro( value, bloque ){
      	console.log( value )
      	this.bloque          = bloque 
      	this.teacher         = value
      	this.dialogMaestro   = true
      },

      exportarRol3 ( ){

      	for( const i in this.gruposRoles ){

      		const { grupo } = this.gruposRoles[i]

      		if( grupo.match('SABATI') && grupo.match('TEENS') ){
      			this.gruposRoles[i].ju1_n = null
      			this.gruposRoles[i].vi1_n = null
      			this.gruposRoles[i].ju2_n = null
      			this.gruposRoles[i].vi2_n = null
      			this.gruposRoles[i].ju3_n = null
      			this.gruposRoles[i].vi3_n = null
      			this.gruposRoles[i].ju4_n = null
      			this.gruposRoles[i].vi4_n = null
      		}else{
      			// this.gruposRoles[i].lu1_n =  this.gruposRoles[i].lu1_n ? this.gruposRoles[i].lu1_n : null
      			// this.gruposRoles[i].ma1_n =  this.gruposRoles[i].ma1_n ? this.gruposRoles[i].ma1_n : null
      			// this.gruposRoles[i].mi1_n =  this.gruposRoles[i].mi1_n ? this.gruposRoles[i].mi1_n : null
      			// this.gruposRoles[i].ju1_n =  this.gruposRoles[i].ju1_n ? this.gruposRoles[i].ju1_n : null
      			// this.gruposRoles[i].vi1_n =  this.gruposRoles[i].vi1_n ? this.gruposRoles[i].vi1_n : null

      			// this.gruposRoles[i].lu2_n =  this.gruposRoles[i].lu2_n ? this.gruposRoles[i].lu2_n : null
      			// this.gruposRoles[i].ma2_n =  this.gruposRoles[i].ma2_n ? this.gruposRoles[i].ma2_n : null
      			// this.gruposRoles[i].mi2_n =  this.gruposRoles[i].mi2_n ? this.gruposRoles[i].mi2_n : null
      			// this.gruposRoles[i].ju2_n =  this.gruposRoles[i].ju2_n ? this.gruposRoles[i].ju2_n : null
      			// this.gruposRoles[i].vi2_n =  this.gruposRoles[i].vi2_n ? this.gruposRoles[i].vi2_n : null

      			// this.gruposRoles[i].lu3_n =  this.gruposRoles[i].lu3_n ? this.gruposRoles[i].lu3_n : null
      			// this.gruposRoles[i].ma3_n =  this.gruposRoles[i].ma3_n ? this.gruposRoles[i].ma3_n : null
      			// this.gruposRoles[i].mi3_n =  this.gruposRoles[i].mi3_n ? this.gruposRoles[i].mi3_n : null
      			// this.gruposRoles[i].ju3_n =  this.gruposRoles[i].ju3_n ? this.gruposRoles[i].ju3_n : null
      			// this.gruposRoles[i].vi3_n =  this.gruposRoles[i].vi3_n ? this.gruposRoles[i].vi3_n : null

      			// this.gruposRoles[i].lu4_n =  this.gruposRoles[i].lu4_n ? this.gruposRoles[i].lu4_n : null
      			// this.gruposRoles[i].ma4_n =  this.gruposRoles[i].ma4_n ? this.gruposRoles[i].ma4_n : null
      			// this.gruposRoles[i].mi4_n =  this.gruposRoles[i].mi4_n ? this.gruposRoles[i].mi4_n : null
      			// this.gruposRoles[i].ju4_n =  this.gruposRoles[i].ju4_n ? this.gruposRoles[i].ju4_n : null
      			// this.gruposRoles[i].vi4_n =  this.gruposRoles[i].vi4_n ? this.gruposRoles[i].vi4_n : null

      		}


      	}

      	let grupos = this.gruposRoles.filter( el => el.deleted == 0 )

      	let data, data2, data3 = []
      	this.exportExcel55( grupos , 'ROL_CLASES')

      },

      abrirComparativa(){
      	this.dialogComparativa.estatus = true
      },

      guardarMaestro( ){

      	if(  !this.opAsignacion ){
      		return this.validarErrorDirecto('Favor de llenar correctamente los datos')
      	}


      	let bloque1 = ["lu1","ma1","mi1", "lu2","ma2","mi2","lu3","ma3","mi3","lu4","ma4","mi4"]

      	let bloque2 = ["ju1","vi1","ju2","vi2","ju3","vi3","ju4","vi4"]

      	let diasSemana = ['lu', 'ma', 'mi'];

      	diasSemana = ( diasSemana.some(dia => this.bloque.includes(dia)) ) ? bloque1 : bloque2 

				const indiceSeleccionado = diasSemana.indexOf(this.bloque);

				let bloques = indiceSeleccionado !== -1 ? diasSemana.slice(indiceSeleccionado) : []
				let bloquesfill = indiceSeleccionado !== -1 ? diasSemana.slice(indiceSeleccionado) : []

      	this.cargar      = true
      
      	// Validar que teacher es....
        let numero_teacher = 0

        // Si el teacher que se modifico, fue de estos bloques... es teacher uno
        if( ['lu1','ma1','mi1','lu2','ma2','mi2','lu3','ma3','mi3','lu4','ma4','mi4'].includes( this.bloque ) ){
       		numero_teacher = 1
        }else{ 
        	numero_teacher = 2 // Si no está, es por que es teacher 2
        }


        let falto    = this.teacher ? this.teacher[`${this.bloque}_n`] ? this.teacher[`${this.bloque}_n`] : 'SIN TEACHER' : 'SIN TEACHER'
	      let idfalto  = this.teacher ? this.teacher[`${this.bloque}`]   : 0

	      // Consultar el salario de quién faltó por que se le va a rebajar
      	const salarioFalto = this.pagos.find( el => el.id_usuario == idfalto )
      	let valor_hora_f = salarioFalto ? salarioFalto.hora_1 : 0

      	// buscar el grupo para sacar las horas que tiene por clase
      	let horas        = this.teacher.horas_dia

      	// Monto a rebajar
      	let monto        = 1 * valor_hora_f
	      
	      let valor_hora_c = 0 
	      let monto_c = 0

	      let existeRegistro = null

      	if( !this.opSinTeacher ){
	      	const salarioCubrio = this.pagos.find( el => el.id_usuario == this.teacherReemplazo.id_usuario )
	        const { lunes, martes, miercoles, jueves, viernes, sabado, domingo } = this.teacher
	      	
	      	// DATOS DE LA PERSONA QUE CUBRIÓ

	      	if( sabado ){

	      		valor_hora_c = salarioCubrio ? salarioCubrio.hora_2 : 0

	      	}else if( domingo ){

	      		valor_hora_c = salarioCubrio ? salarioCubrio.hora_3 : 0

	      	}else{

	      		valor_hora_c = salarioCubrio ? salarioCubrio.hora_1 : 0

	      	}

	      	monto_c      = this.pagoDoble ?  2 * valor_hora_c : 0

		      existeRegistro = this.horasDobles.find( el => el.idgrupo == this.teacher.iderp && el.bloque == this.bloque && el.id_teacher_falto == idfalto )
      	}

      	// Ver el Salario de quién reemplazo al faltista

        let payload = {
          id_maestro     : this.opSinTeacher ? 0 : this.teacherReemplazo.id_trabajador,
          id_usuario     : this.opSinTeacher ? 0 : this.teacherReemplazo.id_usuario,
          id_grupo       : this.teacher.iderp, // ===== idgrupo
          idgrupo        : this.teacher.iderp, // ===== idgrupo
          activo_sn      : 1,
          numero_teacher,
          escuela        : this.teacher.id_unidad_negocio,
          ciclo          : this.ciclo,
          opAsignacion   : this.opAsignacion,
          bloque         : this.bloque,
          bloques,
          arregloTeacher : bloquesfill.fill(this.opSinTeacher ? 0 : this.teacherReemplazo.id_usuario),
          actualizo      : this.getdatosUsuario.iderp,
          id_plantel     : this.teacher.id_plantel, // ===== idgrupo
          grupo          : this.teacher.grupo, // ===== idgrupo,
          teacher        : this.opSinTeacher ? 'SIN TEACHER' : this.teacherReemplazo.nombre_completo,
          grupo_completo : this.teacher,
          pagoDoble      : this.pagoDoble,
          idfalto,
          falto,
          horas_f        : horas,
          valor_hora_f,
          monto,
          cubrio         : this.opSinTeacher ? 'SIN TEACHER' : this.teacherReemplazo.nombre_completo,
					idcubrio       : this.opSinTeacher ? 0 : this.teacherReemplazo.id_usuario,
					valor_hora_c,
					monto_c,
					tipo           :  existeRegistro ? existeRegistro.tipo : null
        }

        if( !this.opSinTeacher ){

	        const { lunes, martes, miercoles, jueves, viernes, sabado, domingo } = this.teacher
        	

        	if( lunes ){
	        	
	        	let { hora_fin, hora_inicio, iderp } = this.teacher

        		let gruposTeacher =  this.gruposRoles.filter( el => { return el[`${this.bloque}`] == this.teacherReemplazo.id_usuario && el.lunes == lunes && el.deleted == 0 && el.iderp != iderp }) 

        		// sacar las horas en las que el grupo se da clase
	        	for( const i in gruposTeacher ){
	        
		        	let { hora_fin, hora_inicio, domingo, sabado, grupo } = gruposTeacher[i]

		        	if( hora_inicio >= this.teacher.hora_inicio &&  hora_inicio < this.teacher.hora_fin || hora_fin > this.teacher.hora_inicio &&  hora_fin <= this.teacher.hora_fin ){

		        		return this.validarErrorDirecto('Se empalma hora y día')
		        	}

		        }
        	}


        	if( martes ){
	        	
	        	let { hora_fin, hora_inicio, iderp } = this.teacher

        		let gruposTeacher =  this.gruposRoles.filter( el => { return el[`${this.bloque}`] == this.teacherReemplazo.id_usuario && el.martes == martes && el.deleted == 0 && el.iderp != iderp }) 

        		// sacar las horas en las que el grupo se da clase
	        	for( const i in gruposTeacher ){
	        
		        	let { hora_fin, hora_inicio, domingo, sabado, grupo } = gruposTeacher[i]

		        	if( hora_inicio >= this.teacher.hora_inicio &&  hora_inicio < this.teacher.hora_fin || hora_fin > this.teacher.hora_inicio &&  hora_fin <= this.teacher.hora_fin ){

		        		return this.validarErrorDirecto('Se empalma hora y día')
		        	}

		        }
        	}


        	if( miercoles ){
	        	
	        	let { hora_fin, hora_inicio, iderp } = this.teacher

        		let gruposTeacher =  this.gruposRoles.filter( el => { return el[`${this.bloque}`] == this.teacherReemplazo.id_usuario && el.miercoles == miercoles && el.deleted == 0 && el.iderp != iderp }) 

        		// sacar las horas en las que el grupo se da clase
	        	for( const i in gruposTeacher ){
	        
		        	let { hora_fin, hora_inicio, domingo, sabado, grupo } = gruposTeacher[i]

		        	if( hora_inicio >= this.teacher.hora_inicio &&  hora_inicio < this.teacher.hora_fin || hora_fin > this.teacher.hora_inicio &&  hora_fin <= this.teacher.hora_fin ){

		        		return this.validarErrorDirecto('Se empalma hora y día')
		        	}

		        }
        	}

        	if( jueves ){
	        	
	        	let { hora_fin, hora_inicio, iderp } = this.teacher

        		let gruposTeacher =  this.gruposRoles.filter( el => { return el[`${this.bloque}`] == this.teacherReemplazo.id_usuario && el.jueves == jueves && el.deleted == 0 && el.iderp != iderp }) 

        		// sacar las horas en las que el grupo se da clase
	        	for( const i in gruposTeacher ){
	        
		        	let { hora_fin, hora_inicio, domingo, sabado, grupo } = gruposTeacher[i]

		        	if( hora_inicio >= this.teacher.hora_inicio &&  hora_inicio < this.teacher.hora_fin || hora_fin > this.teacher.hora_inicio &&  hora_fin <= this.teacher.hora_fin ){

		        		return this.validarErrorDirecto('Se empalma hora y día')
		        	}

		        }
        	}


        	if( viernes ){
	        	
	        	let { hora_fin, hora_inicio, iderp } = this.teacher

        		let gruposTeacher =  this.gruposRoles.filter( el => { return el[`${this.bloque}`] == this.teacherReemplazo.id_usuario && el.viernes == viernes && el.deleted == 0 && el.iderp != iderp }) 

        		// sacar las horas en las que el grupo se da clase
	        	for( const i in gruposTeacher ){
	        
		        	let { hora_fin, hora_inicio, domingo, sabado, grupo } = gruposTeacher[i]

		        	if( hora_inicio >= this.teacher.hora_inicio &&  hora_inicio < this.teacher.hora_fin || hora_fin > this.teacher.hora_inicio &&  hora_fin <= this.teacher.hora_fin ){

		        		return this.validarErrorDirecto('Se empalma hora y día')
		        	}

		        }
        	}

        	if( sabado ){
	        	
	        	let { hora_fin, hora_inicio, iderp } = this.teacher

        		let gruposTeacher =  this.gruposRoles.filter( el => { return el[`${this.bloque}`] == this.teacherReemplazo.id_usuario && el.sabado == sabado && el.deleted == 0 && el.iderp != iderp }) 

        		// sacar las horas en las que el grupo se da clase
	        	for( const i in gruposTeacher ){
	        
		        	let { hora_fin, hora_inicio, domingo, sabado, grupo } = gruposTeacher[i]

		        	if( hora_inicio >= this.teacher.hora_inicio &&  hora_inicio < this.teacher.hora_fin || hora_fin > this.teacher.hora_inicio &&  hora_fin <= this.teacher.hora_fin ){

		        		console.log( this.teacher )
		        		console.log( gruposTeacher[i] )
		        		console.log( this.bloque )
		        		// return this.validarErrorDirecto('Se empalma hora y día')
		        	}

		        }
        	}


        	if( domingo ){
	        	
	        	let { hora_fin, hora_inicio, iderp } = this.teacher

        		let gruposTeacher =  this.gruposRoles.filter( el => { return el[`${this.bloque}`] == this.teacherReemplazo.id_usuario && el.domingo == domingo && el.deleted == 0 && el.iderp != iderp }) 

        		// sacar las horas en las que el grupo se da clase
	        	for( const i in gruposTeacher ){
	        
		        	let { hora_fin, hora_inicio, domingo, sabado, grupo } = gruposTeacher[i]

		        	if( hora_inicio >= this.teacher.hora_inicio &&  hora_inicio < this.teacher.hora_fin || hora_fin > this.teacher.hora_inicio &&  hora_fin <= this.teacher.hora_fin ){

		        		// return this.validarErrorDirecto('Se empalma hora y día')
		        	}

		        }
        	}

        }

        payload[`${this.bloque}`] = this.teacherReemplazo

        this.$http.post(`academico.add.grupoteacher`, payload).then(response=>{
        	this.bloque           = '' 
	      	this.teacher          = null
	      	this.teacherReemplazo = null
	      	this.dialogMaestro    = false
	      	this.opSinTeacher     = 0
        	this.validarSuccess( response.data.message )
          // this.consultar()
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })

      	this.cargar      = false
      },

      guardarReemplazo( ){

      	if( !this.opAsignacion ){ return this.validarErrorDirecto('Favor de llenar todos los datos') }

      	let payload = {
      		idgrupo: this.teacher.iderp, // ===== idgrupo
      		bloque : this.bloque,
      		id_teacher: this.teacherReemplazo,
      		actualizo: this.getdatosUsuario.iderp
      	}

      	// Agregamos el bloque que queremos 
      	payload[`${this.bloque}`] = this.teacherReemplazo

      	if( !this.teacherReemplazo ){
      		return this.validarErrorDirecto('Selecciona un teacher por favor')
      	}

      	this.cargar      = true
      
        this.$http.post('rol.clases.reemplazo', payload ).then(response=>{
        	this.bloque           = '' 
	      	this.teacher          = null
	      	this.teacherReemplazo = null
	      	this.dialogReemplazo  = false
          // this.consultar()
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      getRolados( ){
      	this.cargar      = true

      	const payload = {
      		fecha: this.fechaRol,
      		id_plantel: this.plantelRol
      	}
      	
      	this.rolados = []

        this.$http.post('rol.reemplazos', payload ).then(response=>{
        	this.rolados = response.data.teachers
        	console.log( this.rolados )
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      cargarCatalogos( ){
      	if( !this.ciclo ){
      		return this.validarErrorDirecto('Selecciona un ciclo por favor')
      	}

      	this.cargar      = true
      
        this.$http.post('lms.cron.catalogos', this.ciclo ).then(response=>{
        	this.cargar = false
        	this.consultar()
        	this.validarSuccess( response.data.message )
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      exportarSabatino( ){

      	this.dialogNominaSabatina.estatus = true

      },

      exportarDominical( ){

      	this.dialogNominaQuincena.estatus = true

      	return 
      	let bonos = []// this.cacularBonoTeachers()

      	console.log( bonos )


      	let clasesDobles = this.horasDobles.filter( el => el.tipo == 2 )


      	let bloqueInicial = [1,2].includes( this.selectedHeaders ) ? 1 : 3
      	let bloqueFinal   = [1,2].includes( this.selectedHeaders ) ? 2 : 4

      	// Grupos del SABATINO
      	let gruposNoEliminados = this.gruposRoles.filter( el => { return el.deleted == 0 })
      	// let gruposOficiales    = gruposNoEliminados.filter( el => { return el.sabado == 0 })
      	let gruposOficiales    = gruposNoEliminados


      	for( const i in gruposOficiales ){
      		const { grupo } = gruposOficiales[i]
	      	if( grupo.match('DOMINI') && grupo.match('TEENS') ){
      			gruposOficiales[i].ju1_n = null
      			gruposOficiales[i].ju1   = null
      			gruposOficiales[i].vi1_n = null
      			gruposOficiales[i].vi1   = null
      			gruposOficiales[i].ju2_n = null
      			gruposOficiales[i].ju2   = null
      			gruposOficiales[i].vi2_n = null
      			gruposOficiales[i].vi2   = null
      			gruposOficiales[i].ju3_n = null
      			gruposOficiales[i].ju3   = null
      			gruposOficiales[i].vi3_n = null
      			gruposOficiales[i].vi3   = null
      			gruposOficiales[i].ju4_n = null
      			gruposOficiales[i].ju4   = null
      			gruposOficiales[i].vi4_n = null
      			gruposOficiales[i].vi4   = null
      		}      		
      	}

      	// Vamos a sacar los teachers de cada bloque
        let tlu1 = gruposOficiales.filter( el => { return ( el.lunes || el.domingo || el.sabado ) && el[`lu${bloqueInicial}_n`] })
        														.map((registro) => { return { id_teacher: registro[`lu${bloqueInicial}`], teacher: registro[`lu${bloqueInicial}_n`]  }})

        let tma1 = gruposOficiales.filter( el => { return ( el.martes || el.domingo || el.sabado ) && el[`ma${bloqueInicial}_n`] })
        														.map((registro) => { return { id_teacher: registro[`ma${bloqueInicial}`], teacher: registro[`ma${bloqueInicial}_n`]  }})

        let tmi1 = gruposOficiales.filter( el => { return ( el.miercoles || el.domingo || el.sabado ) && el[`mi${bloqueInicial}_n`] })
        														.map((registro) => { return { id_teacher: registro[`mi${bloqueInicial}`], teacher: registro[`mi${bloqueInicial}_n`]  }})

        let tju1 = gruposOficiales.filter( el => { return ( el.jueves || el.domingo || el.sabado ) && el[`ju${bloqueInicial}_n`] })
        														.map((registro) => { return { id_teacher: registro[`ju${bloqueInicial}`], teacher: registro[`ju${bloqueInicial}_n`]  }})

        let tvi1 = gruposOficiales.filter( el => { return ( el.viernes || el.domingo || el.sabado ) && el[`vi${bloqueInicial}_n`] })
        														.map((registro) => { return { id_teacher: registro[`vi${bloqueInicial}`], teacher: registro[`vi${bloqueInicial}_n`]  }})
        // Vamos a sacar los teachers de cada bloque
        let tlu2 = gruposOficiales.filter( el => { return ( el.lunes || el.domingo || el.sabado ) && el[`lu${bloqueFinal}_n`] })
        														.map((registro) => { return { id_teacher: registro[`lu${bloqueFinal}`], teacher: registro[`lu${bloqueFinal}_n`]  }})

        let tma2 = gruposOficiales.filter( el => { return ( el.martes || el.domingo || el.sabado ) && el[`ma${bloqueFinal}_n`] })
        														.map((registro) => { return { id_teacher: registro[`ma${bloqueFinal}`], teacher: registro[`ma${bloqueFinal}_n`]  }})

        let tmi2 = gruposOficiales.filter( el => { return ( el.miercoles || el.domingo || el.sabado ) && el[`mi${bloqueFinal}_n`] })
        														.map((registro) => { return { id_teacher: registro[`mi${bloqueFinal}`], teacher: registro[`mi${bloqueFinal}_n`]  }})

        let tju2 = gruposOficiales.filter( el => { return ( el.jueves || el.domingo || el.sabado ) && el[`ju${bloqueFinal}_n`] })
        														.map((registro) => { return { id_teacher: registro[`ju${bloqueFinal}`], teacher: registro[`ju${bloqueFinal}_n`]  }})

        let tvi2 = gruposOficiales.filter( el => { return ( el.viernes || el.domingo || el.sabado ) && el[`vi${bloqueFinal}_n`] })
        														.map((registro) => { return { id_teacher: registro[`vi${bloqueFinal}`], teacher: registro[`vi${bloqueFinal}_n`]  }})

        // Concatenamos a los maestros
        let arregloTeachers = tlu1.concat( tma1 ).concat( tmi1 ).concat( tju1 ).concat( tvi1 ).concat(tlu2).concat( tma2 ).concat( tmi2 ).concat( tju2 ).concat( tvi2 )

        // Quitamos los duplicados de los maestros
        let arregloSinDuplicados = Array.from(new Set(arregloTeachers.map(JSON.stringify))).map(JSON.parse);

        // Recorremos los maestros para ir agregando las cosas
				for( const i in arregloSinDuplicados ){

					// Sacamos el id del teacher
					const { id_teacher, teacher } = arregloSinDuplicados[i]

					// Sacamos las clases que le pertenecen al teacher 
					const clasesTeacher = gruposOficiales.filter(el => (['lu', 'ma', 'mi', 'ju', 'vi']
																		.some(dia => el[`${dia}${bloqueInicial}`] == id_teacher) || ['lu', 'ma', 'mi', 'ju', 'vi']
																		.some(dia => el[`${dia}${bloqueFinal}`] == id_teacher)) && ( !el.domingo && !el.sabado && el.horas_dia != 1.5 ));

					// SACAR LAS CLASES DOMINICALES
					const clasesTeacherDomingo = gruposOficiales.filter(el => (['lu', 'ma', 'mi', 'ju', 'vi']
																				.some(dia => el[`${dia}${bloqueInicial}`] == id_teacher ) || ['lu', 'ma', 'mi', 'ju', 'vi']
																				.some(dia => el[`${dia}${bloqueFinal}`] == id_teacher ) ) && el.domingo );

					// SACAR LAS CLASES SABATINO
					const clasesTeacherSabado = gruposOficiales.filter(el => (['lu', 'ma', 'mi', 'ju', 'vi']
																				.some(dia => el[`${dia}${bloqueInicial}`] == id_teacher ) || ['lu', 'ma', 'mi', 'ju', 'vi']
																				.some(dia => el[`${dia}${bloqueFinal}`] == id_teacher ) ) && el.sabado );

					// CLASES DE 1.5 HORAS
					const clasesLMV = gruposOficiales.filter(el => (['lu', 'ma', 'mi', 'ju', 'vi']
																				.some(dia => el[`${dia}${bloqueInicial}`] == id_teacher ) || ['lu', 'ma', 'mi', 'ju', 'vi']
																				.some(dia => el[`${dia}${bloqueFinal}`] == id_teacher ) ) && el.horas_dia == 1.5 );

					// Lo separamos por escuela
					const clasesFast = clasesTeacher.filter( el => { return el.id_unidad_negocio == 2})
					const clasesInbi = clasesTeacher.filter( el => { return el.id_unidad_negocio == 1})

					// CLASES FAST DOMINGO
					const clasesFastDomingo = clasesTeacherDomingo.filter( el => { return el.id_unidad_negocio == 2})
					const clasesInbiDomingo = clasesTeacherDomingo.filter( el => { return el.id_unidad_negocio == 1})

					// CLASES FAST SABADO
					const clasesFastSabado = clasesTeacherSabado.filter( el => { return el.id_unidad_negocio == 2})
					const clasesInbiSabado = clasesTeacherSabado.filter( el => { return el.id_unidad_negocio == 1})

					// CLASES LMV
					const clasesFastLMV = clasesLMV.filter( el => { return el.id_unidad_negocio == 2})
					const clasesInbiLMV = clasesLMV.filter( el => { return el.id_unidad_negocio == 1})

					let clasesFastLV = 0
					let clasesFastLM = 0
					let clasesFastDom = 0
					let clasesInbiLV = 0
					let clasesInbiLM = 0
					let clasesInbiDom = 0


					// CONTADOR ENTRE SEMANA
					let contadorBloquesFast = 0
					for( const i in clasesFast ){

						// ENTRE SEMANA
						contadorBloquesFast += clasesFast[i][`lu${bloqueInicial}`] == id_teacher && clasesFast[i].lunes      ? clasesFast[i].horas_dia : 0
						contadorBloquesFast += clasesFast[i][`ma${bloqueInicial}`] == id_teacher && clasesFast[i].martes     ? clasesFast[i].horas_dia : 0
						contadorBloquesFast += clasesFast[i][`mi${bloqueInicial}`] == id_teacher && clasesFast[i].miercoles  ? clasesFast[i].horas_dia : 0
						contadorBloquesFast += clasesFast[i][`ju${bloqueInicial}`] == id_teacher && clasesFast[i].jueves     ? clasesFast[i].horas_dia : 0
						contadorBloquesFast += clasesFast[i][`vi${bloqueInicial}`] == id_teacher && clasesFast[i].viernes    ? clasesFast[i].horas_dia : 0

						// ENTRE SEMANA
						contadorBloquesFast += clasesFast[i][`lu${bloqueFinal}`] == id_teacher && clasesFast[i].lunes      ? clasesFast[i].horas_dia : 0
						contadorBloquesFast += clasesFast[i][`ma${bloqueFinal}`] == id_teacher && clasesFast[i].martes     ? clasesFast[i].horas_dia : 0
						contadorBloquesFast += clasesFast[i][`mi${bloqueFinal}`] == id_teacher && clasesFast[i].miercoles  ? clasesFast[i].horas_dia : 0
						contadorBloquesFast += clasesFast[i][`ju${bloqueFinal}`] == id_teacher && clasesFast[i].jueves     ? clasesFast[i].horas_dia : 0
						contadorBloquesFast += clasesFast[i][`vi${bloqueFinal}`] == id_teacher && clasesFast[i].viernes    ? clasesFast[i].horas_dia : 0
					}

					clasesFastLV += contadorBloquesFast

					// CONTADOR FAST DOMINGOS
					let contadorBloquesFastDomingo = 0
					for( const i in clasesFastDomingo ){

						// DOMINICALES
						contadorBloquesFastDomingo += clasesFastDomingo[i][`lu${bloqueInicial}`] == id_teacher  ? 1 : 0
						contadorBloquesFastDomingo += clasesFastDomingo[i][`ma${bloqueInicial}`] == id_teacher  ? 1 : 0
						contadorBloquesFastDomingo += clasesFastDomingo[i][`mi${bloqueInicial}`] == id_teacher  ? 1 : 0
						contadorBloquesFastDomingo += clasesFastDomingo[i][`ju${bloqueInicial}`] == id_teacher  ? 1 : 0
						contadorBloquesFastDomingo += clasesFastDomingo[i][`vi${bloqueInicial}`] == id_teacher  ? 1 : 0

						contadorBloquesFastDomingo += clasesFastDomingo[i][`lu${bloqueFinal}`] == id_teacher  ? 1 : 0
						contadorBloquesFastDomingo += clasesFastDomingo[i][`ma${bloqueFinal}`] == id_teacher  ? 1 : 0
						contadorBloquesFastDomingo += clasesFastDomingo[i][`mi${bloqueFinal}`] == id_teacher  ? 1 : 0
						contadorBloquesFastDomingo += clasesFastDomingo[i][`ju${bloqueFinal}`] == id_teacher  ? 1 : 0
						contadorBloquesFastDomingo += clasesFastDomingo[i][`vi${bloqueFinal}`] == id_teacher  ? 1 : 0

						// DOMINICALES
						clasesFastDom += clasesFastDomingo[i][`lu${bloqueInicial}`] == id_teacher  ? 1 : 0
						clasesFastDom += clasesFastDomingo[i][`ma${bloqueInicial}`] == id_teacher  ? 1 : 0
						clasesFastDom += clasesFastDomingo[i][`mi${bloqueInicial}`] == id_teacher  ? 1 : 0
						clasesFastDom += clasesFastDomingo[i][`ju${bloqueInicial}`] == id_teacher  ? 1 : 0
						clasesFastDom += clasesFastDomingo[i][`vi${bloqueInicial}`] == id_teacher  ? 1 : 0

						clasesFastDom += clasesFastDomingo[i][`lu${bloqueFinal}`] == id_teacher  ? 1 : 0
						clasesFastDom += clasesFastDomingo[i][`ma${bloqueFinal}`] == id_teacher  ? 1 : 0
						clasesFastDom += clasesFastDomingo[i][`mi${bloqueFinal}`] == id_teacher  ? 1 : 0
						clasesFastDom += clasesFastDomingo[i][`ju${bloqueFinal}`] == id_teacher  ? 1 : 0
						clasesFastDom += clasesFastDomingo[i][`vi${bloqueFinal}`] == id_teacher  ? 1 : 0
					}

					// CONTADOR FAST SABATINO
					let contadorBloquesFastSabado = 0
					for( const i in clasesFastSabado ){

						// DOMINICALES
						contadorBloquesFastSabado += clasesFastSabado[i][`lu${bloqueInicial}`] == id_teacher  ? 1 : 0
						contadorBloquesFastSabado += clasesFastSabado[i][`ma${bloqueInicial}`] == id_teacher  ? 1 : 0
						contadorBloquesFastSabado += clasesFastSabado[i][`mi${bloqueInicial}`] == id_teacher  ? 1 : 0
						contadorBloquesFastSabado += clasesFastSabado[i][`ju${bloqueInicial}`] == id_teacher  ? 1 : 0
						contadorBloquesFastSabado += clasesFastSabado[i][`vi${bloqueInicial}`] == id_teacher  ? 1 : 0

						contadorBloquesFastSabado += clasesFastSabado[i][`lu${bloqueFinal}`] == id_teacher  ? 1 : 0
						contadorBloquesFastSabado += clasesFastSabado[i][`ma${bloqueFinal}`] == id_teacher  ? 1 : 0
						contadorBloquesFastSabado += clasesFastSabado[i][`mi${bloqueFinal}`] == id_teacher  ? 1 : 0
						contadorBloquesFastSabado += clasesFastSabado[i][`ju${bloqueFinal}`] == id_teacher  ? 1 : 0
						contadorBloquesFastSabado += clasesFastSabado[i][`vi${bloqueFinal}`] == id_teacher  ? 1 : 0
					}

					// CONTADOR CLASES DE 1.5 HORAS
					let contadorBloquesFastLMV = 0
					for( const i in clasesFastLMV ){

						// ENTRE SEMANA
						contadorBloquesFastLMV += clasesFastLMV[i][`lu${bloqueInicial}`] == id_teacher && clasesFastLMV[i].lunes     ? clasesFastLMV[i].horas_dia : 0
						contadorBloquesFastLMV += clasesFastLMV[i][`ma${bloqueInicial}`] == id_teacher && clasesFastLMV[i].martes    ? clasesFastLMV[i].horas_dia : 0
						contadorBloquesFastLMV += clasesFastLMV[i][`mi${bloqueInicial}`] == id_teacher && clasesFastLMV[i].miercoles ? clasesFastLMV[i].horas_dia : 0
						contadorBloquesFastLMV += clasesFastLMV[i][`ju${bloqueInicial}`] == id_teacher && clasesFastLMV[i].jueves    ? clasesFastLMV[i].horas_dia : 0
						contadorBloquesFastLMV += clasesFastLMV[i][`vi${bloqueInicial}`] == id_teacher && clasesFastLMV[i].viernes   ? clasesFastLMV[i].horas_dia : 0

						contadorBloquesFastLMV += clasesFastLMV[i][`lu${bloqueFinal}`] == id_teacher && clasesFastLMV[i].lunes     ? clasesFastLMV[i].horas_dia : 0
						contadorBloquesFastLMV += clasesFastLMV[i][`ma${bloqueFinal}`] == id_teacher && clasesFastLMV[i].martes    ? clasesFastLMV[i].horas_dia : 0
						contadorBloquesFastLMV += clasesFastLMV[i][`mi${bloqueFinal}`] == id_teacher && clasesFastLMV[i].miercoles ? clasesFastLMV[i].horas_dia : 0
						contadorBloquesFastLMV += clasesFastLMV[i][`ju${bloqueFinal}`] == id_teacher && clasesFastLMV[i].jueves    ? clasesFastLMV[i].horas_dia : 0
						contadorBloquesFastLMV += clasesFastLMV[i][`vi${bloqueFinal}`] == id_teacher && clasesFastLMV[i].viernes   ? clasesFastLMV[i].horas_dia : 0

						// CLASES// ENTRE SEMANA
						clasesFastLM += clasesFastLMV[i][`lu${bloqueInicial}`] == id_teacher && clasesFastLMV[i].lunes     ? 1 : 0
						clasesFastLM += clasesFastLMV[i][`ma${bloqueInicial}`] == id_teacher && clasesFastLMV[i].martes    ? 1 : 0
						clasesFastLM += clasesFastLMV[i][`mi${bloqueInicial}`] == id_teacher && clasesFastLMV[i].miercoles ? 1 : 0
						clasesFastLM += clasesFastLMV[i][`ju${bloqueInicial}`] == id_teacher && clasesFastLMV[i].jueves    ? 1 : 0
						clasesFastLM += clasesFastLMV[i][`vi${bloqueInicial}`] == id_teacher && clasesFastLMV[i].viernes   ? 1 : 0

						clasesFastLM += clasesFastLMV[i][`lu${bloqueFinal}`] == id_teacher && clasesFastLMV[i].lunes     ? 1 : 0
						clasesFastLM += clasesFastLMV[i][`ma${bloqueFinal}`] == id_teacher && clasesFastLMV[i].martes    ? 1 : 0
						clasesFastLM += clasesFastLMV[i][`mi${bloqueFinal}`] == id_teacher && clasesFastLMV[i].miercoles ? 1 : 0
						clasesFastLM += clasesFastLMV[i][`ju${bloqueFinal}`] == id_teacher && clasesFastLMV[i].jueves    ? 1 : 0
						clasesFastLM += clasesFastLMV[i][`vi${bloqueFinal}`] == id_teacher && clasesFastLMV[i].viernes   ? 1 : 0
					}

					// INBIIIIIIIIIII CLASES ENTRE SEMANA
					// CONTADOR ENTRE SEMANA
					let contadorBloquesINBI = 0
					for( const i in clasesInbi ){

						// ENTRE SEMANA BOQUE 1 o 3
						contadorBloquesINBI += clasesInbi[i][`lu${bloqueInicial}`] == id_teacher && clasesInbi[i].lunes     ? clasesInbi[i].horas_dia : 0
						contadorBloquesINBI += clasesInbi[i][`ma${bloqueInicial}`] == id_teacher && clasesInbi[i].martes    ? clasesInbi[i].horas_dia : 0
						contadorBloquesINBI += clasesInbi[i][`mi${bloqueInicial}`] == id_teacher && clasesInbi[i].miercoles ? clasesInbi[i].horas_dia : 0
						contadorBloquesINBI += clasesInbi[i][`ju${bloqueInicial}`] == id_teacher && clasesInbi[i].jueves    ? clasesInbi[i].horas_dia : 0
						contadorBloquesINBI += clasesInbi[i][`vi${bloqueInicial}`] == id_teacher && clasesInbi[i].viernes   ? clasesInbi[i].horas_dia : 0
						// ENTRE SEMANA BOQUE 2 o 4
						contadorBloquesINBI += clasesInbi[i][`lu${bloqueFinal}`] == id_teacher && clasesInbi[i].lunes     ? clasesInbi[i].horas_dia : 0
						contadorBloquesINBI += clasesInbi[i][`ma${bloqueFinal}`] == id_teacher && clasesInbi[i].martes    ? clasesInbi[i].horas_dia : 0
						contadorBloquesINBI += clasesInbi[i][`mi${bloqueFinal}`] == id_teacher && clasesInbi[i].miercoles ? clasesInbi[i].horas_dia : 0
						contadorBloquesINBI += clasesInbi[i][`ju${bloqueFinal}`] == id_teacher && clasesInbi[i].jueves    ? clasesInbi[i].horas_dia : 0
						contadorBloquesINBI += clasesInbi[i][`vi${bloqueFinal}`] == id_teacher && clasesInbi[i].viernes   ? clasesInbi[i].horas_dia : 0

						// ENTRE SEMANA BOQUE 1 o 3
						clasesInbiLV += clasesInbi[i][`lu${bloqueInicial}`] == id_teacher && clasesInbi[i].lunes     ? 1 : 0
						clasesInbiLV += clasesInbi[i][`ma${bloqueInicial}`] == id_teacher && clasesInbi[i].martes    ? 1 : 0
						clasesInbiLV += clasesInbi[i][`mi${bloqueInicial}`] == id_teacher && clasesInbi[i].miercoles ? 1 : 0
						clasesInbiLV += clasesInbi[i][`ju${bloqueInicial}`] == id_teacher && clasesInbi[i].jueves    ? 1 : 0
						clasesInbiLV += clasesInbi[i][`vi${bloqueInicial}`] == id_teacher && clasesInbi[i].viernes   ? 1 : 0
						// ENTRE SEMANA BOQUE 2 o 4
						clasesInbiLV += clasesInbi[i][`lu${bloqueFinal}`] == id_teacher && clasesInbi[i].lunes     ? 1 : 0
						clasesInbiLV += clasesInbi[i][`ma${bloqueFinal}`] == id_teacher && clasesInbi[i].martes    ? 1 : 0
						clasesInbiLV += clasesInbi[i][`mi${bloqueFinal}`] == id_teacher && clasesInbi[i].miercoles ? 1 : 0
						clasesInbiLV += clasesInbi[i][`ju${bloqueFinal}`] == id_teacher && clasesInbi[i].jueves    ? 1 : 0
						clasesInbiLV += clasesInbi[i][`vi${bloqueFinal}`] == id_teacher && clasesInbi[i].viernes   ? 1 : 0

					}

					// CONTADOR ENTRE SEMANA
					let contadorBloquesInbiDomingo = 0
					for( const i in clasesInbiDomingo ){

						// DOMINICALES
						contadorBloquesInbiDomingo += clasesInbiDomingo[i][`lu${bloqueInicial}`] == id_teacher ? 1 : 0
						contadorBloquesInbiDomingo += clasesInbiDomingo[i][`ma${bloqueInicial}`] == id_teacher ? 1 : 0
						contadorBloquesInbiDomingo += clasesInbiDomingo[i][`mi${bloqueInicial}`] == id_teacher ? 1 : 0
						contadorBloquesInbiDomingo += clasesInbiDomingo[i][`ju${bloqueInicial}`] == id_teacher ? 1 : 0
						contadorBloquesInbiDomingo += clasesInbiDomingo[i][`vi${bloqueInicial}`] == id_teacher ? 1 : 0

						contadorBloquesInbiDomingo += clasesInbiDomingo[i][`lu${bloqueFinal}`] == id_teacher ? 1 : 0
						contadorBloquesInbiDomingo += clasesInbiDomingo[i][`ma${bloqueFinal}`] == id_teacher ? 1 : 0
						contadorBloquesInbiDomingo += clasesInbiDomingo[i][`mi${bloqueFinal}`] == id_teacher ? 1 : 0
						contadorBloquesInbiDomingo += clasesInbiDomingo[i][`ju${bloqueFinal}`] == id_teacher ? 1 : 0
						contadorBloquesInbiDomingo += clasesInbiDomingo[i][`vi${bloqueFinal}`] == id_teacher ? 1 : 0

						clasesInbiDom += clasesInbiDomingo[i][`lu${bloqueInicial}`] == id_teacher ? 1 : 0
						clasesInbiDom += clasesInbiDomingo[i][`ma${bloqueInicial}`] == id_teacher ? 1 : 0
						clasesInbiDom += clasesInbiDomingo[i][`mi${bloqueInicial}`] == id_teacher ? 1 : 0
						clasesInbiDom += clasesInbiDomingo[i][`ju${bloqueInicial}`] == id_teacher ? 1 : 0
						clasesInbiDom += clasesInbiDomingo[i][`vi${bloqueInicial}`] == id_teacher ? 1 : 0

						clasesInbiDom += clasesInbiDomingo[i][`lu${bloqueFinal}`] == id_teacher ? 1 : 0
						clasesInbiDom += clasesInbiDomingo[i][`ma${bloqueFinal}`] == id_teacher ? 1 : 0
						clasesInbiDom += clasesInbiDomingo[i][`mi${bloqueFinal}`] == id_teacher ? 1 : 0
						clasesInbiDom += clasesInbiDomingo[i][`ju${bloqueFinal}`] == id_teacher ? 1 : 0
						clasesInbiDom += clasesInbiDomingo[i][`vi${bloqueFinal}`] == id_teacher ? 1 : 0

					}

					// CONTADOR ENTRE SEMANA
					let contadorBloquesInbiSabado = 0
					for( const i in clasesInbiSabado ){

						const { grupo } = clasesInbiSabado[i]

						if( grupo.match('TEENS') ){



							if( clasesInbiSabado[i][`lu${bloqueInicial}`] == id_teacher ){

								contadorBloquesInbiSabado += 3
							}

							if( clasesInbiSabado[i][`lu${bloqueFinal}`] == id_teacher ){

								contadorBloquesInbiSabado += 3
							}

						}else{
							// DOMINICALES
							contadorBloquesInbiSabado += clasesInbiSabado[i][`lu${bloqueInicial}`] == id_teacher ? 1 : 0
							contadorBloquesInbiSabado += clasesInbiSabado[i][`ma${bloqueInicial}`] == id_teacher ? 1 : 0
							contadorBloquesInbiSabado += clasesInbiSabado[i][`mi${bloqueInicial}`] == id_teacher ? 1 : 0
							contadorBloquesInbiSabado += clasesInbiSabado[i][`ju${bloqueInicial}`] == id_teacher ? 1 : 0
							contadorBloquesInbiSabado += clasesInbiSabado[i][`vi${bloqueInicial}`] == id_teacher ? 1 : 0

							contadorBloquesInbiSabado += clasesInbiSabado[i][`lu${bloqueFinal}`] == id_teacher ? 1 : 0
							contadorBloquesInbiSabado += clasesInbiSabado[i][`ma${bloqueFinal}`] == id_teacher ? 1 : 0
							contadorBloquesInbiSabado += clasesInbiSabado[i][`mi${bloqueFinal}`] == id_teacher ? 1 : 0
							contadorBloquesInbiSabado += clasesInbiSabado[i][`ju${bloqueFinal}`] == id_teacher ? 1 : 0
							contadorBloquesInbiSabado += clasesInbiSabado[i][`vi${bloqueFinal}`] == id_teacher ? 1 : 0
						}
					}

					// CONTADOR ENTRE SEMANA
					let contadorBloquesInbiLMV = 0
					for( const i in clasesInbiLMV ){

						// ENTRE SEMANA
						contadorBloquesInbiLMV += clasesInbiLMV[i][`lu${bloqueInicial}`] == id_teacher && clasesInbiLMV[i].lunes     ? clasesInbiLMV[i].horas_dia : 0
						contadorBloquesInbiLMV += clasesInbiLMV[i][`ma${bloqueInicial}`] == id_teacher && clasesInbiLMV[i].martes    ? clasesInbiLMV[i].horas_dia : 0
						contadorBloquesInbiLMV += clasesInbiLMV[i][`mi${bloqueInicial}`] == id_teacher && clasesInbiLMV[i].miercoles ? clasesInbiLMV[i].horas_dia : 0
						contadorBloquesInbiLMV += clasesInbiLMV[i][`ju${bloqueInicial}`] == id_teacher && clasesInbiLMV[i].jueves    ? clasesInbiLMV[i].horas_dia : 0
						contadorBloquesInbiLMV += clasesInbiLMV[i][`vi${bloqueInicial}`] == id_teacher && clasesInbiLMV[i].viernes   ? clasesInbiLMV[i].horas_dia : 0

						contadorBloquesInbiLMV += clasesInbiLMV[i][`lu${bloqueFinal}`] == id_teacher && clasesInbiLMV[i].lunes     ? clasesInbiLMV[i].horas_dia : 0
						contadorBloquesInbiLMV += clasesInbiLMV[i][`ma${bloqueFinal}`] == id_teacher && clasesInbiLMV[i].martes    ? clasesInbiLMV[i].horas_dia : 0
						contadorBloquesInbiLMV += clasesInbiLMV[i][`mi${bloqueFinal}`] == id_teacher && clasesInbiLMV[i].miercoles ? clasesInbiLMV[i].horas_dia : 0
						contadorBloquesInbiLMV += clasesInbiLMV[i][`ju${bloqueFinal}`] == id_teacher && clasesInbiLMV[i].jueves    ? clasesInbiLMV[i].horas_dia : 0
						contadorBloquesInbiLMV += clasesInbiLMV[i][`vi${bloqueFinal}`] == id_teacher && clasesInbiLMV[i].viernes   ? clasesInbiLMV[i].horas_dia : 0


						clasesInbiLM += clasesInbiLMV[i][`lu${bloqueInicial}`] == id_teacher && clasesInbiLMV[i].lunes     ? 1 : 0
						clasesInbiLM += clasesInbiLMV[i][`ma${bloqueInicial}`] == id_teacher && clasesInbiLMV[i].martes    ? 1 : 0
						clasesInbiLM += clasesInbiLMV[i][`mi${bloqueInicial}`] == id_teacher && clasesInbiLMV[i].miercoles ? 1 : 0
						clasesInbiLM += clasesInbiLMV[i][`ju${bloqueInicial}`] == id_teacher && clasesInbiLMV[i].jueves    ? 1 : 0
						clasesInbiLM += clasesInbiLMV[i][`vi${bloqueInicial}`] == id_teacher && clasesInbiLMV[i].viernes   ? 1 : 0

						clasesInbiLM += clasesInbiLMV[i][`lu${bloqueFinal}`] == id_teacher && clasesInbiLMV[i].lunes     ? 1 : 0
						clasesInbiLM += clasesInbiLMV[i][`ma${bloqueFinal}`] == id_teacher && clasesInbiLMV[i].martes    ? 1 : 0
						clasesInbiLM += clasesInbiLMV[i][`mi${bloqueFinal}`] == id_teacher && clasesInbiLMV[i].miercoles ? 1 : 0
						clasesInbiLM += clasesInbiLMV[i][`ju${bloqueFinal}`] == id_teacher && clasesInbiLMV[i].jueves    ? 1 : 0
						clasesInbiLM += clasesInbiLMV[i][`vi${bloqueFinal}`] == id_teacher && clasesInbiLMV[i].viernes   ? 1 : 0
					}


					const existeCategoria = this.pagos.find( el => el.id_usuario  == id_teacher )

					let hora_1 = existeCategoria ? existeCategoria.hora_1 : 0
					let hora_2 = existeCategoria ? existeCategoria.hora_2 : 0
					let hora_3 = existeCategoria ? existeCategoria.hora_3 : 0

					contadorBloquesFastSabado = 0
					contadorBloquesInbiSabado = 0



					// AGREGAREMOS LOS PAGOS
					arregloSinDuplicados[i]['$_HORA_L_V']  = hora_1
					arregloSinDuplicados[i]['$_HORA_SAB']  = hora_2
					arregloSinDuplicados[i]['$_HORA_DOM']  = hora_3

					/***********************************************************************/
					arregloSinDuplicados[i]['CLASES_ENTRE_SEMANA_FAST']  = clasesFastLV
					arregloSinDuplicados[i]['HORAS_ENTRE_SEMANA_FAST']   = contadorBloquesFast

					arregloSinDuplicados[i]['CLASES_DOMINGO_FAST']       = clasesFastDom
					arregloSinDuplicados[i]['HORAS_DOMINGO_FAST']        = contadorBloquesFastDomingo

					// arregloSinDuplicados[i]['CLASES_SABADO_FAST']        = clasesFastSabado.length
					// arregloSinDuplicados[i]['HORAS_SABADO_FAST']         = contadorBloquesFastSabado

					arregloSinDuplicados[i]['CLASES_LMV_FAST']           = clasesFastLM
					arregloSinDuplicados[i]['HORAS_LMV_FAST']            = contadorBloquesFastLMV

					arregloSinDuplicados[i]['CLASES_TOTALTES_FAST']      = clasesFastLV + clasesFastDom + clasesFastLM // + clasesFastSabado.length
					arregloSinDuplicados[i]['HORAS_TOTALES_FAST']        = contadorBloquesFast + contadorBloquesFastDomingo + contadorBloquesFastLMV // + contadorBloquesFastSabado

					// COSTOS HORAS
					arregloSinDuplicados[i]['PAGO_ENTRE_SEMANA_FAST']   = contadorBloquesFast * hora_1
					arregloSinDuplicados[i]['PAGO_DOMINGO_FAST']        = contadorBloquesFastDomingo * hora_3
					// arregloSinDuplicados[i]['PAGO_SABADO_FAST']         = contadorBloquesFastSabado * hora_2
					arregloSinDuplicados[i]['PAGO_LMV_FAST']            = contadorBloquesFastLMV * hora_1
					arregloSinDuplicados[i]['PAGO_TOTAL_FAST']          = ( contadorBloquesFast * hora_1 ) + ( contadorBloquesFastDomingo * hora_3 ) + ( contadorBloquesFastLMV * hora_1 )
					// arregloSinDuplicados[i]['PAGO_TOTAL_FAST']          = ( contadorBloquesFast * hora_1 ) + ( contadorBloquesFastDomingo * hora_3 ) + ( contadorBloquesFastSabado * hora_2 ) + ( contadorBloquesFastLMV * hora_3 )

					/************************************************************************/

					arregloSinDuplicados[i]['CLASES_ENTRE_SEMANA_INBI']  = clasesInbiLV
					arregloSinDuplicados[i]['HORAS_ENTRE_SEMANA_INBI']   = contadorBloquesINBI

					arregloSinDuplicados[i]['CLASES_DOMINGO_INBI']       = clasesInbiDom
					arregloSinDuplicados[i]['HORAS_DOMINGO_INBI']        = contadorBloquesInbiDomingo

					// arregloSinDuplicados[i]['CLASES_SABADO_INBI']        = clasesInbiSabado.length
					// arregloSinDuplicados[i]['HORAS_SABADO_INBI']         = contadorBloquesInbiSabado

					arregloSinDuplicados[i]['CLASES_LMV_INBI']           = clasesInbiLM
					arregloSinDuplicados[i]['HORAS_LMV_INBI']            = contadorBloquesInbiLMV

					arregloSinDuplicados[i]['CLASES_TOTALTES_INBI']      =  clasesInbiLV + clasesInbiLM + clasesInbiDom // + clasesFastSabado.length
					arregloSinDuplicados[i]['HORAS_TOTALES_INBI']        = contadorBloquesINBI + contadorBloquesInbiDomingo + contadorBloquesInbiLMV + contadorBloquesInbiSabado

					arregloSinDuplicados[i]['PAGO_ENTRE_SEMANA_INBI']   = contadorBloquesINBI * hora_1
					arregloSinDuplicados[i]['PAGO_DOMINGO_INBI']        = contadorBloquesInbiDomingo * hora_3
					// arregloSinDuplicados[i]['PAGO_SABADO_INBI']         = contadorBloquesInbiSabado * hora_2
					arregloSinDuplicados[i]['PAGO_LMV_INBI']            = contadorBloquesInbiLMV * hora_1
					arregloSinDuplicados[i]['PAGO_TOTAL_INBI']          = ( contadorBloquesINBI * hora_1 ) + ( contadorBloquesInbiDomingo * hora_3 ) + ( contadorBloquesInbiLMV * hora_1 )
					// arregloSinDuplicados[i]['PAGO_TOTAL_INBI']          = ( contadorBloquesINBI * hora_1 ) + ( contadorBloquesInbiDomingo * hora_3 ) + ( contadorBloquesInbiLMV * hora_3 ) + ( contadorBloquesInbiSabado * hora_2 )

					/************************************************************************/



					arregloSinDuplicados[i]['CLASES_TOTALTES']           = clasesFastLV + clasesFastDom + clasesFastLM + clasesInbiLV + clasesInbiLM + clasesInbiDom

					arregloSinDuplicados[i]['HORAS_TOTALES']             = contadorBloquesFast + contadorBloquesFastDomingo + contadorBloquesFastLMV + contadorBloquesINBI + contadorBloquesInbiDomingo + contadorBloquesInbiLMV + contadorBloquesFastSabado + contadorBloquesInbiSabado

					arregloSinDuplicados[i]['PAGO_ENTRE_SEMANA']         = ( contadorBloquesINBI * hora_1 ) + ( contadorBloquesFast * hora_1 )
					arregloSinDuplicados[i]['PAGO_DOMINGO']              = ( contadorBloquesInbiDomingo * hora_3 ) + ( contadorBloquesFastDomingo * hora_3 )
					// arregloSinDuplicados[i]['PAGO_SABADO']               = ( contadorBloquesInbiSabado * hora_2 ) + ( contadorBloquesFastSabado * hora_2 )
					arregloSinDuplicados[i]['PAGO_LMV']                  = ( contadorBloquesInbiLMV * hora_1 ) + ( contadorBloquesFastLMV * hora_1 )

					// arregloSinDuplicados[i]['PAGO_TOTAL']                = ( contadorBloquesFast * hora_1 ) + ( contadorBloquesFastDomingo * hora_3 ) + ( contadorBloquesFastSabado * hora_2 ) + ( contadorBloquesFastLMV * hora_3 ) + ( contadorBloquesINBI * hora_1 ) + ( contadorBloquesInbiDomingo * hora_3 ) + ( contadorBloquesInbiLMV * hora_3 ) + ( contadorBloquesInbiSabado * hora_2 )

					/************************************************************************/

					// CLASES DOBLES
					const pagosDoblesTeachers = clasesDobles.filter(el => [`lu${bloqueInicial}`, `ma${bloqueInicial}`, `mi${bloqueInicial}`, `ju${bloqueInicial}`, `vi${bloqueInicial}`].includes( el.bloque ) && el.id_teacher_reemplazo == id_teacher || [`lu${bloqueFinal}`, `ma${bloqueFinal}`, `mi${bloqueFinal}`, `ju${bloqueFinal}`, `vi${bloqueFinal}`].includes( el.bloque ) && el.id_teacher_reemplazo == id_teacher);

					const rebajesDobles = clasesDobles.filter(el => [`lu${bloqueInicial}`, `ma${bloqueInicial}`, `mi${bloqueInicial}`, `ju${bloqueInicial}`, `vi${bloqueInicial}`].includes( el.bloque ) && el.id_teacher_falto == id_teacher || [`lu${bloqueFinal}`, `ma${bloqueFinal}`, `mi${bloqueFinal}`, `ju${bloqueFinal}`, `vi${bloqueFinal}`].includes( el.bloque ) && el.id_teacher_falto == id_teacher);

					let pagoDoble = pagosDoblesTeachers.map(item => item.monto_pago).reduce((prev, curr) => prev + curr, 0)
					let rebajeDoble = rebajesDobles.map(item => item.monto_rebaje).reduce((prev, curr) => prev + curr, 0)
					
					arregloSinDuplicados[i]['PAGO_DOBLES'] = pagoDoble
					arregloSinDuplicados[i]['REBAJES']     = rebajeDoble

					/************************************************************************/


					// BONO 
					const existeBono = null // bonos.find( el => el.id_teacher == id_teacher )

					let totalBono = existeBono ? existeBono.bono : 0

					// arregloSinDuplicados[i]['BONO'] = totalBono


					arregloSinDuplicados[i]['PAGO_TOTAL']                = ( contadorBloquesFast * hora_1 ) + ( contadorBloquesFastDomingo * hora_3 ) + ( contadorBloquesFastLMV * hora_1 ) + ( contadorBloquesINBI * hora_1 ) + ( contadorBloquesInbiDomingo * hora_3 ) + ( contadorBloquesInbiLMV * hora_1 ) + pagoDoble - rebajeDoble + totalBono
				}

				let bloque = [1,2].includes( this.selectedHeaders ) ? 1 : 2



				// for( const i in bonos ){

				// 	const { id_teacher,teacher } = bonos[i]

				// 	const existeTeacher = arregloSinDuplicados.find( el => el.id_teacher == id_teacher )

				// 	if( !existeTeacher){
				// 		console.log( teacher )
				// 	}

				// }

				// Objeto para almacenar los valores únicos de cada columna

        let arregloLV = Array.from(new Set(arregloSinDuplicados.map((registro) => { return { valor_hora: registro[`$_HORA_L_V`]  }}).map(JSON.stringify))).map(JSON.parse);

        let arregloSabatino = Array.from(new Set(arregloSinDuplicados.map((registro) => { return { valor_hora: registro[`$_HORA_SAB`]  }}).map(JSON.stringify))).map(JSON.parse);

        let arregloDominical = Array.from(new Set(arregloSinDuplicados.map((registro) => { return { valor_hora: registro[`$_HORA_DOM`]}}).map(JSON.stringify))).map(JSON.parse);

        for(const i in arregloSabatino ){
        	arregloSabatino[i]['conteo'] = arregloSinDuplicados.filter( el => el['$_HORA_SAB'] == arregloSabatino[i].valor_hora ).length
        }

        for(const i in arregloLV ){
        	arregloLV[i]['conteo'] = arregloSinDuplicados.filter( el => el['$_HORA_L_V'] == arregloLV[i].valor_hora ).length
        }

        for(const i in arregloDominical ){
        	arregloDominical[i]['conteo'] = arregloSinDuplicados.filter( el => el['$_HORA_DOM'] == arregloDominical[i].valor_hora ).length
        }

      	this.exportExcel( arregloSinDuplicados  , 'ROL_CLASES', bloque, arregloSabatino, arregloLV, arregloDominical )

      },

      validarHoraAsistencia(horaInicio, horaAsistencia) {
		    // Convertir las horas a minutos
		    var inicioMinutos = this.obtenerMinutos(horaInicio);
    		var asistenciaMinutos = this.obtenerMinutos(horaAsistencia);
		    
		    // Verificar si la hora de asistencia es al menos 15 minutos antes de la hora de inicio
		    if (inicioMinutos - asistenciaMinutos >= 15) {
		        return true;
		    } else {
		        return false;
		    }
			},

			obtenerMinutos(hora) {
		    var partesHora = hora.split(":");
		    var horas = parseInt(partesHora[0]);
		    var minutos = parseInt(partesHora[1]);
		    return horas * 60 + minutos;
			},


			compararHorasInicio(eventoA, eventoB) {
		    // Convertir las horas de inicio a minutos
		    var minutosA = this.obtenerMinutos(eventoA.hora_inicio);
		    var minutosB = this.obtenerMinutos(eventoB.hora_inicio);
		    
		    // Comparar los minutos
		    if (minutosA < minutosB) {
		        return -1;
		    } else if (minutosA > minutosB) {
		        return 1;
		    } else {
		        return 0;
		    }
			},

      cacularBonoTeachers(){
	    	let gruposActivos = this.gruposRoles.filter( el => { return el.deleted == 0 && !el.grupo.match('ONLINE') })


				// Ordenar el arreglo de eventos por la hora de inicio
				gruposActivos.sort(this.compararHorasInicio);

	    	// VEAMOS SI TIENE BONO EL SABADO FAST INBI SIN TEENS
	    	// Vamos a sacar los teachers de cada bloque
	      let sa1 = gruposActivos.filter( el => { return ( el.sabado && !el.grupo.match('TEENS') ) && el[`lu${1}_n`] && el[`lu${1}_hora_asistencia`] })
	    							.map((registro) => { return { id_teacher: registro[`lu${1}`], teacher: registro[`lu${1}_n`]  }})
				let sa2 = gruposActivos.filter( el => { return ( el.sabado && !el.grupo.match('TEENS') ) && el[`lu${2}_n`] && el[`lu${2}_hora_asistencia`] })
	    							.map((registro) => { return { id_teacher: registro[`lu${2}`], teacher: registro[`lu${2}_n`]  }})
				let sa3 = gruposActivos.filter( el => { return ( el.sabado && !el.grupo.match('TEENS') ) && el[`lu${3}_n`] && el[`lu${3}_hora_asistencia`] })
	    							.map((registro) => { return { id_teacher: registro[`lu${3}`], teacher: registro[`lu${3}_n`]  }})
				let sa4 = gruposActivos.filter( el => { return ( el.sabado && !el.grupo.match('TEENS') ) && el[`lu${4}_n`] && el[`lu${4}_hora_asistencia`] })
	    							.map((registro) => { return { id_teacher: registro[`lu${4}`], teacher: registro[`lu${4}_n`]  }})

	    	// Vamos a sacar los teachers de cada bloque
	      let sa11 = gruposActivos.filter( el => { return ( el.sabado && !el.grupo.match('TEENS') ) && el[`ju${1}_n`] && el[`ju${1}_hora_asistencia`] })
	    							.map((registro) => { return { id_teacher: registro[`ju${1}`], teacher: registro[`ju${1}_n`]  }})
				let sa22 = gruposActivos.filter( el => { return ( el.sabado && !el.grupo.match('TEENS') ) && el[`ju${2}_n`] && el[`ju${2}_hora_asistencia`] })
	    							.map((registro) => { return { id_teacher: registro[`ju${2}`], teacher: registro[`ju${2}_n`]  }})
				let sa33 = gruposActivos.filter( el => { return ( el.sabado && !el.grupo.match('TEENS') ) && el[`ju${3}_n`] && el[`ju${3}_hora_asistencia`] })
	    							.map((registro) => { return { id_teacher: registro[`ju${3}`], teacher: registro[`ju${3}_n`]  }})
				let sa44 = gruposActivos.filter( el => { return ( el.sabado && !el.grupo.match('TEENS') ) && el[`ju${4}_n`] && el[`ju${4}_hora_asistencia`] })
	    							.map((registro) => { return { id_teacher: registro[`ju${4}`], teacher: registro[`ju${4}_n`]  }})


	      let sabadoCompleto = sa1.concat( sa2 ).concat( sa3 ).concat( sa4 ).concat( sa11 ).concat( sa22 ).concat( sa33 ).concat( sa44 )
	      let sabadoTeacherDup  = sa1.concat( sa11 )

	      let sabadoTeacher = Array.from(new Set(sabadoTeacherDup.map(JSON.stringify))).map(JSON.parse);

	      // Conteo

	      for( const i in sabadoTeacher ){

	      	const { id_teacher } = sabadoTeacher[i]

	      	sabadoTeacher[i]['conteo'] = sabadoCompleto.filter( el => el.id_teacher == id_teacher ).length

	      }

	      sabadoTeacher = sabadoTeacher.filter( el => el.conteo >= 4 )

	      let teachersBonoSabatino = []

	      for( const i in sabadoTeacher ){

	      	const { id_teacher, teacher } = sabadoTeacher[i]

	      	let conteoNoRetardos = 0

	      	let valida1 = gruposActivos.find( el => { return ( el.sabado && !el.grupo.match('TEENS') ) && el[`lu${1}`] == id_teacher })
	      	let valida2 = gruposActivos.find( el => { return ( el.sabado && !el.grupo.match('TEENS') ) && el[`lu${2}`] == id_teacher })
	      	let valida3 = gruposActivos.find( el => { return ( el.sabado && !el.grupo.match('TEENS') ) && el[`lu${3}`] == id_teacher })
	      	let valida4 = gruposActivos.find( el => { return ( el.sabado && !el.grupo.match('TEENS') ) && el[`lu${4}`] == id_teacher })

	      	// Validamos las horas 
	      	if( valida1 ){
	      		const { hora_inicio, lu1_hora_asistencia } = valida1
	      		conteoNoRetardos += this.validarHoraAsistencia( hora_inicio.substr(0,5), lu1_hora_asistencia.substr(11,5) ) ? 1 : 0
	      	}

	      	// Validamos las horas 
	      	if( valida2 ){
	      		const { hora_inicio, lu2_hora_asistencia } = valida1
	      		conteoNoRetardos += this.validarHoraAsistencia( hora_inicio.substr(0,5), lu2_hora_asistencia.substr(11,5) ) ? 1 : 0
	      	}

	      	// Validamos las horas 
	      	if( valida3 ){
	      		const { hora_inicio, lu3_hora_asistencia } = valida1
	      		conteoNoRetardos += this.validarHoraAsistencia( hora_inicio.substr(0,5), lu3_hora_asistencia.substr(11,5) ) ? 1 : 0
	      	}

	      	// Validamos las horas 
	      	if( valida4 ){
	      		const { hora_inicio, lu4_hora_asistencia } = valida1
	      		conteoNoRetardos += this.validarHoraAsistencia( hora_inicio.substr(0,5), lu4_hora_asistencia.substr(11,5) ) ? 1 : 0
	      	}

	      	sabadoTeacher[i]['conteoNoRetardos'] = conteoNoRetardos

	      } 

	      sabadoTeacher = sabadoTeacher.filter( el => el.conteoNoRetardos >= 3 )

	      // *************************************************************************************
	      // VEAMOS SI TIENE BONO EL SABADO FAST INBI SIN TEENS
	    	// Vamos a sacar los teachers de cada bloque
	      let do1 = gruposActivos.filter( el => { return ( el.domingo && !el.grupo.match('TEENS') ) && el[`lu${1}_n`] && el[`lu${1}_hora_asistencia`] })
	    							.map((registro) => { return { id_teacher: registro[`lu${1}`], teacher: registro[`lu${1}_n`]  }})
				let do2 = gruposActivos.filter( el => { return ( el.domingo && !el.grupo.match('TEENS') ) && el[`lu${2}_n`] && el[`lu${2}_hora_asistencia`] })
	    							.map((registro) => { return { id_teacher: registro[`lu${2}`], teacher: registro[`lu${2}_n`]  }})
				let do3 = gruposActivos.filter( el => { return ( el.domingo && !el.grupo.match('TEENS') ) && el[`lu${3}_n`] && el[`lu${3}_hora_asistencia`] })
	    							.map((registro) => { return { id_teacher: registro[`lu${3}`], teacher: registro[`lu${3}_n`]  }})
				let do4 = gruposActivos.filter( el => { return ( el.domingo && !el.grupo.match('TEENS') ) && el[`lu${4}_n`] && el[`lu${4}_hora_asistencia`] })
	    							.map((registro) => { return { id_teacher: registro[`lu${4}`], teacher: registro[`lu${4}_n`]  }})

	    	// Vamos a sacar los teachers de cada bloque
	      let do11 = gruposActivos.filter( el => { return ( el.domingo && !el.grupo.match('TEENS') ) && el[`ju${1}_n`] && el[`ju${1}_hora_asistencia`] })
	    							.map((registro) => { return { id_teacher: registro[`ju${1}`], teacher: registro[`ju${1}_n`]  }})
				let do22 = gruposActivos.filter( el => { return ( el.domingo && !el.grupo.match('TEENS') ) && el[`ju${2}_n`] && el[`ju${2}_hora_asistencia`] })
	    							.map((registro) => { return { id_teacher: registro[`ju${2}`], teacher: registro[`ju${2}_n`]  }})
				let do33 = gruposActivos.filter( el => { return ( el.domingo && !el.grupo.match('TEENS') ) && el[`ju${3}_n`] && el[`ju${3}_hora_asistencia`] })
	    							.map((registro) => { return { id_teacher: registro[`ju${3}`], teacher: registro[`ju${3}_n`]  }})
				let do44 = gruposActivos.filter( el => { return ( el.domingo && !el.grupo.match('TEENS') ) && el[`ju${4}_n`] && el[`ju${4}_hora_asistencia`] })
	    							.map((registro) => { return { id_teacher: registro[`ju${4}`], teacher: registro[`ju${4}_n`]  }})


	      let domingoCompleto = do1.concat( do2 ).concat( do3 ).concat( do4 ).concat( do11 ).concat( do22 ).concat( do33 ).concat( do44 )
	      let domingoTeacherDup  = do1.concat( do11 )

	      let domingoTeacher = Array.from(new Set(domingoTeacherDup.map(JSON.stringify))).map(JSON.parse);

	      // Conteo

	      for( const i in domingoTeacher ){

	      	const { id_teacher } = domingoTeacher[i]

	      	domingoTeacher[i]['conteo'] = domingoCompleto.filter( el => el.id_teacher == id_teacher ).length

	      }

	      domingoTeacher = domingoTeacher.filter( el => el.conteo >= 4 )

	      for( const i in domingoTeacher ){

	      	const { id_teacher, teacher } = domingoTeacher[i]

	      	let conteoNoRetardos = 0

	      	let valida1 = gruposActivos.find( el => { return ( el.domingo && !el.grupo.match('TEENS') ) && ( el[`lu${1}`] == id_teacher ) })
	      	let valida2 = gruposActivos.find( el => { return ( el.domingo && !el.grupo.match('TEENS') ) && ( el[`lu${2}`] == id_teacher ) })
	      	let valida3 = gruposActivos.find( el => { return ( el.domingo && !el.grupo.match('TEENS') ) && ( el[`lu${3}`] == id_teacher ) })
	      	let valida4 = gruposActivos.find( el => { return ( el.domingo && !el.grupo.match('TEENS') ) && ( el[`lu${4}`] == id_teacher ) })

	      	// Validamos las horas 
	      	if( valida1 ){
	      		const { hora_inicio, lu1_hora_asistencia } = valida1
	      		conteoNoRetardos += this.validarHoraAsistencia( hora_inicio.substr(0,5), lu1_hora_asistencia.substr(11,5) ) ? 1 : 0
	      	}

	      	// Validamos las horas 
	      	if( valida2 ){
	      		const { hora_inicio, lu2_hora_asistencia } = valida2
	      		conteoNoRetardos += this.validarHoraAsistencia( hora_inicio.substr(0,5), lu2_hora_asistencia.substr(11,5) ) ? 1 : 0
	      	}

	      	// Validamos las horas 
	      	if( valida3 ){
	      		const { hora_inicio, lu3_hora_asistencia } = valida3 
	      		conteoNoRetardos += this.validarHoraAsistencia( hora_inicio.substr(0,5), lu3_hora_asistencia.substr(11,5) ) ? 1 : 0
	      	}

	      	// Validamos las horas 
	      	if( valida4 ){
	      		const { hora_inicio, lu4_hora_asistencia } = valida4 
	      		conteoNoRetardos += this.validarHoraAsistencia( hora_inicio.substr(0,5), lu4_hora_asistencia.substr(11,5) ) ? 1 : 0
	      	}

	      	domingoTeacher[i]['conteoNoRetardos'] = conteoNoRetardos

	      } 

	      domingoTeacher = domingoTeacher.filter( el => el.conteoNoRetardos >= 3 )

	      // *************************************************************************************
	      // VEAMOS SI TIENE BONO EL SABADO FAST INBI SIN TEENS
	    	// Vamos a sacar los teachers de cada bloque
	      let lu1 = gruposActivos.filter( el => { return ( el.lunes ) && el[`lu${1}_n`] && el[`lu${1}_hora_asistencia`] })
	    							.map((registro) => { return { id_teacher: registro[`lu${1}`], teacher: registro[`lu${1}_n`]  }})
				let lu2 = gruposActivos.filter( el => { return ( el.lunes ) && el[`lu${2}_n`] && el[`lu${2}_hora_asistencia`] })
	    							.map((registro) => { return { id_teacher: registro[`lu${2}`], teacher: registro[`lu${2}_n`]  }})
				let lu3 = gruposActivos.filter( el => { return ( el.lunes ) && el[`lu${3}_n`] && el[`lu${3}_hora_asistencia`] })
	    							.map((registro) => { return { id_teacher: registro[`lu${3}`], teacher: registro[`lu${3}_n`]  }})
				let lu4 = gruposActivos.filter( el => { return ( el.lunes ) && el[`lu${4}_n`] && el[`lu${4}_hora_asistencia`] })
	    							.map((registro) => { return { id_teacher: registro[`lu${4}`], teacher: registro[`lu${4}_n`]  }})

	      let lunesCompleto = lu1.concat( lu2 ).concat( lu3 ).concat( lu4 )
	      let lunesTeacherDup  = lu1

	      let lunesTeacher = Array.from(new Set(lunesTeacherDup.map(JSON.stringify))).map(JSON.parse);

	      // Conteo

	      for( const i in lunesTeacher ){

	      	const { id_teacher } = lunesTeacher[i]

	      	lunesTeacher[i]['conteo'] = lunesCompleto.filter( el => el.id_teacher == id_teacher ).length

	      }

	      lunesTeacher = lunesTeacher.filter( el => el.conteo >= 3 )

	      for( const i in lunesTeacher ){

	      	const { id_teacher, teacher } = lunesTeacher[i]

	      	let conteoNoRetardos = 0

	      	let valida1 = gruposActivos.find( el => { return ( el.lunes ) && el[`lu${1}`] == id_teacher })
	      	let valida2 = gruposActivos.find( el => { return ( el.lunes ) && el[`lu${2}`] == id_teacher })
	      	let valida3 = gruposActivos.find( el => { return ( el.lunes ) && el[`lu${3}`] == id_teacher })
	      	let valida4 = gruposActivos.find( el => { return ( el.lunes ) && el[`lu${4}`] == id_teacher })

	      	// Validamos las horas 
	      	if( valida1 ){
	      		const { hora_inicio, lu1_hora_asistencia } = valida1
	      		conteoNoRetardos += this.validarHoraAsistencia( hora_inicio.substr(0,5), lu1_hora_asistencia.substr(11,5) ) ? 1 : 0
	      	}

	      	// Validamos las horas 
	      	if( valida2 ){
	      		const { hora_inicio, lu2_hora_asistencia } = valida2
	      		conteoNoRetardos += this.validarHoraAsistencia( hora_inicio.substr(0,5), lu2_hora_asistencia.substr(11,5) ) ? 1 : 0
	      	}

	      	// Validamos las horas 
	      	if( valida3 ){
	      		const { hora_inicio, lu3_hora_asistencia } = valida3
	      		conteoNoRetardos += this.validarHoraAsistencia( hora_inicio.substr(0,5), lu3_hora_asistencia.substr(11,5) ) ? 1 : 0
	      	}

	      	// Validamos las horas 
	      	if( valida4 ){
	      		const { hora_inicio, lu4_hora_asistencia } = valida4
	      		conteoNoRetardos += this.validarHoraAsistencia( hora_inicio.substr(0,5), lu4_hora_asistencia.substr(11,5) ) ? 1 : 0
	      	}

	      	lunesTeacher[i]['conteoNoRetardos'] = conteoNoRetardos

	      } 

	      lunesTeacher = lunesTeacher.filter( el => el.conteoNoRetardos >= 3 )


	      // *************************************************************************************
	      // VEAMOS SI TIENE BONO EL SABADO FAST INBI SIN TEENS
	    	// Vamos a sacar los teachers de cada bloque
	      let ma1 = gruposActivos.filter( el => { return ( el.martes ) && el[`ma${1}_n`] && el[`ma${1}_hora_asistencia`] })
	    							.map((registro) => { return { id_teacher: registro[`ma${1}`], teacher: registro[`ma${1}_n`]  }})
				let ma2 = gruposActivos.filter( el => { return ( el.martes ) && el[`ma${2}_n`] && el[`ma${2}_hora_asistencia`] })
	    							.map((registro) => { return { id_teacher: registro[`ma${2}`], teacher: registro[`ma${2}_n`]  }})
				let ma3 = gruposActivos.filter( el => { return ( el.martes ) && el[`ma${3}_n`] && el[`ma${3}_hora_asistencia`] })
	    							.map((registro) => { return { id_teacher: registro[`ma${3}`], teacher: registro[`ma${3}_n`]  }})
				let ma4 = gruposActivos.filter( el => { return ( el.martes ) && el[`ma${4}_n`] && el[`ma${4}_hora_asistencia`] })
	    							.map((registro) => { return { id_teacher: registro[`ma${4}`], teacher: registro[`ma${4}_n`]  }})

	      let martesCompleto = ma1.concat( ma2 ).concat( ma3 ).concat( ma4 )
	      let martesTeacherDup  = ma1

	      let martesTeacher = Array.from(new Set(martesTeacherDup.map(JSON.stringify))).map(JSON.parse);

	      // Conteo

	      for( const i in martesTeacher ){

	      	const { id_teacher } = martesTeacher[i]

	      	martesTeacher[i]['conteo'] = martesCompleto.filter( el => el.id_teacher == id_teacher ).length

	      }

	      martesTeacher = martesTeacher.filter( el => el.conteo >= 4 )

	      for( const i in martesTeacher ){

	      	const { id_teacher, teacher } = martesTeacher[i]

	      	let conteoNoRetardos = 0

	      	let valida1 = gruposActivos.find( el => { return ( el.martes ) && el[`ma${1}`] == id_teacher })
	      	let valida2 = gruposActivos.find( el => { return ( el.martes ) && el[`ma${2}`] == id_teacher })
	      	let valida3 = gruposActivos.find( el => { return ( el.martes ) && el[`ma${3}`] == id_teacher })
	      	let valida4 = gruposActivos.find( el => { return ( el.martes ) && el[`ma${4}`] == id_teacher })

	      	// Validamos las horas 
	      	if( valida1 ){
	      		const { hora_inicio, ma1_hora_asistencia } = valida1
	      		conteoNoRetardos += this.validarHoraAsistencia( hora_inicio.substr(0,5), ma1_hora_asistencia.substr(11,5) ) ? 1 : 0
	      	}

	      	// Validamos las horas 
	      	if( valida2 ){
	      		const { hora_inicio, ma2_hora_asistencia } = valida2
	      		conteoNoRetardos += this.validarHoraAsistencia( hora_inicio.substr(0,5), ma2_hora_asistencia.substr(11,5) ) ? 1 : 0
	      	}

	      	// Validamos las horas 
	      	if( valida3 ){
	      		const { hora_inicio, ma3_hora_asistencia } = valida3
	      		conteoNoRetardos += this.validarHoraAsistencia( hora_inicio.substr(0,5), ma3_hora_asistencia.substr(11,5) ) ? 1 : 0
	      	}

	      	// Validamos las horas 
	      	if( valida4 ){
	      		const { hora_inicio, ma4_hora_asistencia } = valida4
	      		conteoNoRetardos += this.validarHoraAsistencia( hora_inicio.substr(0,5), ma4_hora_asistencia.substr(11,5) ) ? 1 : 0
	      	}

	      	martesTeacher[i]['conteoNoRetardos'] = conteoNoRetardos

	      } 

	      martesTeacher = martesTeacher.filter( el => el.conteoNoRetardos >= 3 )


	      // *************************************************************************************
	      // VEAMOS SI TIENE BONO EL SABADO FAST INBI SIN TEENS
	    	// Vamos a sacar los teachers de cada bloque
	      let mi1 = gruposActivos.filter( el => { return ( el.miercoles ) && el[`mi${1}_n`] && el[`mi${1}_hora_asistencia`] })
	    							.map((registro) => { return { id_teacher: registro[`mi${1}`], teacher: registro[`mi${1}_n`]  }})
				let mi2 = gruposActivos.filter( el => { return ( el.miercoles ) && el[`mi${2}_n`] && el[`mi${2}_hora_asistencia`] })
	    							.map((registro) => { return { id_teacher: registro[`mi${2}`], teacher: registro[`mi${2}_n`]  }})
				let mi3 = gruposActivos.filter( el => { return ( el.miercoles ) && el[`mi${3}_n`] && el[`mi${3}_hora_asistencia`] })
	    							.map((registro) => { return { id_teacher: registro[`mi${3}`], teacher: registro[`mi${3}_n`]  }})
				let mi4 = gruposActivos.filter( el => { return ( el.miercoles ) && el[`mi${4}_n`] && el[`mi${4}_hora_asistencia`] })
	    							.map((registro) => { return { id_teacher: registro[`mi${4}`], teacher: registro[`mi${4}_n`]  }})

	      let miercolesCompleto = mi1.concat( mi2 ).concat( mi3 ).concat( mi4 )
	      let miercolesTeacherDup  = mi1

	      let miercolesTeacher = Array.from(new Set(miercolesTeacherDup.map(JSON.stringify))).map(JSON.parse);

	      // Conteo

	      for( const i in miercolesTeacher ){

	      	const { id_teacher } = miercolesTeacher[i]

	      	miercolesTeacher[i]['conteo'] = miercolesCompleto.filter( el => el.id_teacher == id_teacher ).length

	      }

	      miercolesTeacher = miercolesTeacher.filter( el => el.conteo >= 4 )

	      for( const i in miercolesTeacher ){

	      	const { id_teacher, teacher } = miercolesTeacher[i]

	      	let conteoNoRetardos = 0

	      	let valida1 = gruposActivos.find( el => { return ( el.miercoles ) && el[`mi${1}`] == id_teacher })
	      	let valida2 = gruposActivos.find( el => { return ( el.miercoles ) && el[`mi${2}`] == id_teacher })
	      	let valida3 = gruposActivos.find( el => { return ( el.miercoles ) && el[`mi${3}`] == id_teacher })
	      	let valida4 = gruposActivos.find( el => { return ( el.miercoles ) && el[`mi${4}`] == id_teacher })

	      	// Validamos las horas 
	      	if( valida1 ){
	      		const { hora_inicio, mi1_hora_asistencia } = valida1
	      		conteoNoRetardos += this.validarHoraAsistencia( hora_inicio.substr(0,5), mi1_hora_asistencia.substr(11,5) ) ? 1 : 0
	      	}

	      	// Validamos las horas 
	      	if( valida2 ){
	      		const { hora_inicio, mi2_hora_asistencia } = valida2
	      		conteoNoRetardos += this.validarHoraAsistencia( hora_inicio.substr(0,5), mi2_hora_asistencia.substr(11,5) ) ? 1 : 0
	      	}

	      	// Validamos las horas 
	      	if( valida3 ){
	      		const { hora_inicio, mi3_hora_asistencia } = valida3
	      		conteoNoRetardos += this.validarHoraAsistencia( hora_inicio.substr(0,5), mi3_hora_asistencia.substr(11,5) ) ? 1 : 0
	      	}

	      	// Validamos las horas 
	      	if( valida4 ){
	      		const { hora_inicio, mi4_hora_asistencia } = valida4
	      		conteoNoRetardos += this.validarHoraAsistencia( hora_inicio.substr(0,5), mi4_hora_asistencia.substr(11,5) ) ? 1 : 0
	      	}

	      	miercolesTeacher[i]['conteoNoRetardos'] = conteoNoRetardos

	      } 

	      miercolesTeacher = miercolesTeacher.filter( el => el.conteoNoRetardos >= 3 )


	      // *************************************************************************************
	      // VEAMOS SI TIENE BONO EL SABADO FAST INBI SIN TEENS
	    	// Vamos a sacar los teachers de cada bloque
	      let ju1 = gruposActivos.filter( el => { return ( el.jueves ) && el[`ju${1}_n`] && el[`ju${1}_hora_asistencia`] })
	    							.map((registro) => { return { id_teacher: registro[`ju${1}`], teacher: registro[`ju${1}_n`]  }})
				let ju2 = gruposActivos.filter( el => { return ( el.jueves ) && el[`ju${2}_n`] && el[`ju${2}_hora_asistencia`] })
	    							.map((registro) => { return { id_teacher: registro[`ju${2}`], teacher: registro[`ju${2}_n`]  }})
				let ju3 = gruposActivos.filter( el => { return ( el.jueves ) && el[`ju${3}_n`] && el[`ju${3}_hora_asistencia`] })
	    							.map((registro) => { return { id_teacher: registro[`ju${3}`], teacher: registro[`ju${3}_n`]  }})
				let ju4 = gruposActivos.filter( el => { return ( el.jueves ) && el[`ju${4}_n`] && el[`ju${4}_hora_asistencia`] })
	    							.map((registro) => { return { id_teacher: registro[`ju${4}`], teacher: registro[`ju${4}_n`]  }})

	      let juevesCompleto = ju1.concat( ju2 ).concat( ju3 ).concat( ju4 )
	      let juevesTeacherDup  = ju1

	      let juevesTeacher = Array.from(new Set(juevesTeacherDup.map(JSON.stringify))).map(JSON.parse);

	      // Conteo

	      for( const i in juevesTeacher ){

	      	const { id_teacher } = juevesTeacher[i]

	      	juevesTeacher[i]['conteo'] = juevesCompleto.filter( el => el.id_teacher == id_teacher ).length

	      }

	      juevesTeacher = juevesTeacher.filter( el => el.conteo >= 4 )

	      for( const i in juevesTeacher ){

	      	const { id_teacher, teacher } = juevesTeacher[i]

	      	let conteoNoRetardos = 0

	      	let valida1 = gruposActivos.find( el => { return ( el.jueves ) && el[`ju${1}`] == id_teacher })
	      	let valida2 = gruposActivos.find( el => { return ( el.jueves ) && el[`ju${2}`] == id_teacher })
	      	let valida3 = gruposActivos.find( el => { return ( el.jueves ) && el[`ju${3}`] == id_teacher })
	      	let valida4 = gruposActivos.find( el => { return ( el.jueves ) && el[`ju${4}`] == id_teacher })

	      	// Validamos las horas 
	      	if( valida1 ){
	      		const { hora_inicio, ju1_hora_asistencia } = valida1
	      		conteoNoRetardos += this.validarHoraAsistencia( hora_inicio.substr(0,5), ju1_hora_asistencia.substr(11,5) ) ? 1 : 0
	      	}

	      	// Validamos las horas 
	      	if( valida2 ){
	      		const { hora_inicio, ju2_hora_asistencia } = valida2
	      		conteoNoRetardos += this.validarHoraAsistencia( hora_inicio.substr(0,5), ju2_hora_asistencia.substr(11,5) ) ? 1 : 0
	      	}

	      	// Validamos las horas 
	      	if( valida3 ){
	      		const { hora_inicio, ju3_hora_asistencia } = valida3
	      		conteoNoRetardos += this.validarHoraAsistencia( hora_inicio.substr(0,5), ju3_hora_asistencia.substr(11,5) ) ? 1 : 0
	      	}

	      	// Validamos las horas 
	      	if( valida4 ){
	      		const { hora_inicio, ju4_hora_asistencia } = valida4
	      		conteoNoRetardos += this.validarHoraAsistencia( hora_inicio.substr(0,5), ju4_hora_asistencia.substr(11,5) ) ? 1 : 0
	      	}

	      	juevesTeacher[i]['conteoNoRetardos'] = conteoNoRetardos

	      } 

	      juevesTeacher = juevesTeacher.filter( el => el.conteoNoRetardos >= 3 )


	      // *************************************************************************************
	      // VEAMOS SI TIENE BONO EL SABADO FAST INBI SIN TEENS
	    	// Vamos a sacar los teachers de cada bloque
	      let vi1 = gruposActivos.filter( el => { return ( el.viernes ) && el[`vi${1}_n`] && el[`vi${1}_hora_asistencia`] })
	    							.map((registro) => { return { id_teacher: registro[`vi${1}`], teacher: registro[`vi${1}_n`]  }})
				let vi2 = gruposActivos.filter( el => { return ( el.viernes ) && el[`vi${2}_n`] && el[`vi${2}_hora_asistencia`] })
	    							.map((registro) => { return { id_teacher: registro[`vi${2}`], teacher: registro[`vi${2}_n`]  }})
				let vi3 = gruposActivos.filter( el => { return ( el.viernes ) && el[`vi${3}_n`] && el[`vi${3}_hora_asistencia`] })
	    							.map((registro) => { return { id_teacher: registro[`vi${3}`], teacher: registro[`vi${3}_n`]  }})
				let vi4 = gruposActivos.filter( el => { return ( el.viernes ) && el[`vi${4}_n`] && el[`vi${4}_hora_asistencia`] })
	    							.map((registro) => { return { id_teacher: registro[`vi${4}`], teacher: registro[`vi${4}_n`]  }})

	      let viernesCompleto = vi1.concat( vi2 ).concat( vi3 ).concat( vi4 )
	      let viernesTeacherDup  = vi1

	      let viernesTeacher = Array.from(new Set(viernesTeacherDup.map(JSON.stringify))).map(JSON.parse);

	      // Conteo

	      for( const i in viernesTeacher ){

	      	const { id_teacher } = viernesTeacher[i]

	      	viernesTeacher[i]['conteo'] = viernesCompleto.filter( el => el.id_teacher == id_teacher ).length

	      }

	      viernesTeacher = viernesTeacher.filter( el => el.conteo >= 4 )

	      for( const i in viernesTeacher ){

	      	const { id_teacher, teacher } = viernesTeacher[i]

	      	let conteoNoRetardos = 0

	      	let valida1 = gruposActivos.find( el => { return ( el.viernes ) && el[`vi${1}`] == id_teacher })
	      	let valida2 = gruposActivos.find( el => { return ( el.viernes ) && el[`vi${2}`] == id_teacher })
	      	let valida3 = gruposActivos.find( el => { return ( el.viernes ) && el[`vi${3}`] == id_teacher })
	      	let valida4 = gruposActivos.find( el => { return ( el.viernes ) && el[`vi${4}`] == id_teacher })

	      	// Validamos las horas 
	      	if( valida1 ){
	      		const { hora_inicio, ju1_hora_asistencia } = valida1
	      		conteoNoRetardos += this.validarHoraAsistencia( hora_inicio.substr(0,5), ju1_hora_asistencia.substr(11,5) ) ? 1 : 0
	      	}

	      	// Validamos las horas 
	      	if( valida2 ){
	      		const { hora_inicio, ju2_hora_asistencia } = valida2
	      		conteoNoRetardos += this.validarHoraAsistencia( hora_inicio.substr(0,5), ju2_hora_asistencia.substr(11,5) ) ? 1 : 0
	      	}

	      	// Validamos las horas 
	      	if( valida3 ){
	      		const { hora_inicio, ju3_hora_asistencia } = valida3
	      		conteoNoRetardos += this.validarHoraAsistencia( hora_inicio.substr(0,5), ju3_hora_asistencia.substr(11,5) ) ? 1 : 0
	      	}

	      	// Validamos las horas 
	      	if( valida4 ){
	      		const { hora_inicio, ju4_hora_asistencia } = valida4
	      		conteoNoRetardos += this.validarHoraAsistencia( hora_inicio.substr(0,5), ju4_hora_asistencia.substr(11,5) ) ? 1 : 0
	      	}

	      	viernesTeacher[i]['conteoNoRetardos'] = conteoNoRetardos

	      } 

	      viernesTeacher = viernesTeacher.filter( el => el.conteoNoRetardos >= 3 )

	      let allTeachersDu = lunesTeacher.concat( martesTeacher).concat( juevesTeacher ).concat( miercolesTeacher ).concat( viernesTeacher ).concat( sabadoTeacher ).concat( domingoTeacher )

	      // Utilizar un conjunto para mantener un registro de los id_teacher únicos
		    var idTeacherUnicos = new Set();
		    // Utilizar filter para filtrar los elementos del arreglo
		    // y mantener solo aquellos con id_teacher que no estén en el conjunto
		    this.teachersBonos = allTeachersDu.filter(function (elemento) {
		        // Si el id_teacher ya está en el conjunto, retorna false (no lo incluye)
		        // Si no está en el conjunto, lo agrega y retorna true (lo incluye)
		        if (idTeacherUnicos.has(elemento.id_teacher)) {
		          return false;
		        } else {
		          idTeacherUnicos.add(elemento.id_teacher);
		          return true;
		        }
		    });

		    // Ahora... hay que ponerle qué días, estára....
		    for( const i in this.teachersBonos ){

		    	const { id_teacher, teacher } = this.teachersBonos[i]


		    	this.teachersBonos[i]['eliminable'] = 0
		    	this.teachersBonos[i]['bono']       = 0
		    	this.teachersBonos[i]['bono_l']     = 0

		    	const existeSabado    = sabadoTeacher.find( el => el.id_teacher == id_teacher )
		    	const existeDomingo   = domingoTeacher.find( el => el.id_teacher == id_teacher )
		    	const existeLunes     = lunesTeacher.find( el => el.id_teacher == id_teacher )
		    	const existeMartes    = martesTeacher.find( el => el.id_teacher == id_teacher )
		    	const existeMiercoles = juevesTeacher.find( el => el.id_teacher == id_teacher )
		    	const existeJueves    = miercolesTeacher.find( el => el.id_teacher == id_teacher )
		    	const existeViernes   = viernesTeacher.find( el => el.id_teacher == id_teacher )



		    	// Un fin de semana y entre semana medio turno
		    	if( ( existeSabado || existeDomingo ) && ( existeLunes && existeMartes && existeMiercoles && existeJueves && existeViernes )){

		    		// Significa que no debería estar rolada en nigun fin de semana
		    		let existeEntreSemana = gruposActivos.filter( el => { return ( el.lunes == 1 || el.martes == 1 || el.miercoles == 1 || el.jueves == 1 || el.viernes == 1 ) && ( el[`lu${1}`] == id_teacher || el[`ma${1}`] == id_teacher || el[`mi${1}`] == id_teacher || el[`ju${1}`] == id_teacher || el[`vi${1}`] == id_teacher ) })

		    		let medioTurno = 0
		    		let medioTurno2 = 0

		    		for( const i in existeEntreSemana ){

		    			const { hora_inicio } = existeEntreSemana[i]

		    			if( hora_inicio.substr(0,5) <= "16:00" ){ medioTurno = 1 }
		    			if( hora_inicio.substr(0,5) >= "16:00" ){ medioTurno2 = 1 }

		    		}

		    		let grupos = existeEntreSemana.map((registro) => { return registro.grupo })

		    		// FULL
		    		if( medioTurno && medioTurno2 ){
		    			this.teachersBonos[i]['categoria'] = 1
		    			this.teachersBonos[i]['bono']      = 1000
		    		}else{ // 1/2 turno + fin de semana
		    			this.teachersBonos[i]['categoria'] = 2
		    			this.teachersBonos[i]['bono']      = 500
		    		}
		    		
		    	}


		    	// Un fin de semana y entre semana medio turno
		    	if( ( existeSabado || existeDomingo ) && ( existeLunes || existeMartes || existeMiercoles || existeJueves || existeViernes )){

		    		// Significa que no debería estar rolada en nigun fin de semana
		    		let existeEntreSemana = gruposActivos.filter( el => { return ( el.lunes == 1 || el.martes == 1 || el.miercoles == 1 || el.jueves == 1 || el.viernes == 1 ) && ( el[`lu${1}`] == id_teacher || el[`ma${1}`] == id_teacher || el[`mi${1}`] == id_teacher || el[`ju${1}`] == id_teacher || el[`vi${1}`] == id_teacher ) })

		    		let medioTurno = 0
		    		let medioTurno2 = 0

		    		for( const i in existeEntreSemana ){

		    			const { hora_inicio } = existeEntreSemana[i]

		    			if( hora_inicio.substr(0,5) <= "16:00" ){ medioTurno = 1 }
		    			if( hora_inicio.substr(0,5) >= "16:00" ){ medioTurno2 = 1 }

		    		}

		    		let grupos = existeEntreSemana.map((registro) => { return registro.grupo })

		    		// FULL
		    		if( medioTurno && medioTurno2 ){
		    			this.teachersBonos[i]['categoria'] = 1
		    			this.teachersBonos[i]['bono_l']    = 1000
		    		}else{ // 1/2 turno + fin de semana
		    			this.teachersBonos[i]['categoria'] = 2
		    			this.teachersBonos[i]['bono_l']    = 500
		    		}
		    		
		    	}


		    	// SOLO FIN DE SEMANA
		    	if( ( existeSabado || existeDomingo ) && ( !existeLunes && !existeMartes && !existeMiercoles && !existeJueves && !existeViernes )){

		    		// Significa que no debería estar rolada en nigun fin de semana
		    		let existeEntreSemana = gruposActivos.filter( el => { return ( el.sabado == 0 && el.domingo == 0 ) && ( el[`lu${1}`] == id_teacher && el[`ma${1}`] == id_teacher && el[`mi${1}`] == id_teacher && el[`ju${1}`] == id_teacher && el[`vi${1}`] == id_teacher ) })

		    		if( !existeEntreSemana.length ){
		    			this.teachersBonos[i]['categoria'] = 3
		    			this.teachersBonos[i]['bono']      = 350
		    			this.teachersBonos[i]['bono_l']    = 350
		    		}else{
		    			this.teachersBonos[i]['eliminable'] = 1
		    			this.teachersBonos[i]['bono']       = 0
		    			this.teachersBonos[i]['bono_l']     = 0
		    		}
		    		

		    	}

		    	// SOLO ENTRE SEMANA
		    	if( ( !existeSabado && !existeDomingo ) && ( existeLunes && existeMartes && existeMiercoles && existeJueves && existeViernes )){

		    		// Significa que no debería estar rolada en nigun fin de semana
		    		let existeFinSemana = gruposActivos.filter( el => { return ( el.sabado == 1 || el.domingo == 1 ) && ( el[`lu${1}`] == id_teacher || el[`ma${1}`] == id_teacher || el[`mi${1}`] == id_teacher || el[`ju${1}`] == id_teacher || el[`vi${1}`] == id_teacher ) })

		    		if( !existeFinSemana.length ){
		    			this.teachersBonos[i]['categoria'] = 4
	            this.teachersBonos[i]['bono']      = 500
		    			this.teachersBonos[i]['bono_l']    = 500
		    		}else{
		    			this.teachersBonos[i]['eliminable'] = 1
		    			this.teachersBonos[i]['bono']       = 0
		    			this.teachersBonos[i]['bono_l']     = 0
		    		}
		    		

		    	}

	        // SOLO ENTRE SEMANA
	        if( ( !existeSabado && !existeDomingo ) && ( existeLunes || existeMartes || existeMiercoles || existeJueves || existeViernes )){

	          // Significa que no debería estar rolada en nigun fin de semana
	          let existeFinSemana = gruposActivos.filter( el => { return ( el.sabado == 1 || el.domingo == 1 ) && ( el[`lu${1}`] == id_teacher || el[`ma${1}`] == id_teacher || el[`mi${1}`] == id_teacher || el[`ju${1}`] == id_teacher || el[`vi${1}`] == id_teacher ) })

	          if( !existeFinSemana.length ){
	            this.teachersBonos[i]['categoria'] = 4
	            this.teachersBonos[i]['bono_l']    = 500
	          }else{
	            this.teachersBonos[i]['eliminable'] = 1
	            this.teachersBonos[i]['bono_l']     = 0
	          }
	          

	        }
		    }


		    this.teachersBonos.push({
		    	id_teacher:  10000000000000000,
					teacher:     'TOTAL',
					categoria:   0,
					bono:        this.teachersBonos.map(item => item.bono).reduce((prev, curr) => prev + curr, 0) ,
					eliminable:  0,
		    })

	    	return this.teachersBonos
	    },

      copiarPortapapeles ( item ) {

      	// Expresión regular para encontrar enlaces en el texto
				var expresionRegular = /https?:\/\/[^\s]+/g;

				// Usar el método match() para obtener todos los enlaces en un arreglo
				var enlaces = item.match(expresionRegular);

        navigator.clipboard.writeText( enlaces[0] ).then(() => {
          this.textoCopiado = 'COPIADO'
        }).catch(err => {
          console.log('Something went wrong', err);
        })
      },

      enviarRol( ){
      	if( !this.ciclo ){
      		return this.validarErrorDirecto('Selecciona un ciclo por favor')
      	}

      	this.cargar      = true
      
        this.$http.post('rol.clases.liberar', this.gruposRoles ).then(response=>{
          this.consultar()
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      abrirZoom( item ){

				// Expresión regular para encontrar enlaces en el texto
				var expresionRegular = /https?:\/\/[^\s]+/g;

				// Usar el método match() para obtener todos los enlaces en un arreglo
				var enlaces = item.match(expresionRegular)[0];

				window.open(enlaces, "_blank");


      },

      saveSalon( value ){
      	console.log( value )
      	const payload = {
      		idrol_clases: value.idrol_clases,
      		id_salon    : value.id_salon_2,
      	}

      	this.cargar  = true

        return this.$http.post('roles.salon.grupo', payload).then((response) => {

          // this.consultar()
          this.cargar    = false

        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },	

      tieneClaseConIdTeacher(el, dias, bloque, id_teacher) {
			  return dias.some(dia => el[`${dia}${bloque}`] == id_teacher);
			},

			verBitacora( bloque, id_teacher, idrol_clases ){

				this.dialogBitacora = false 

      	const payload = {
					bloque, 
					id_teacher, 
					idrol_clases
      	}

      	this.cargar      = true
      
        this.$http.post(`bitacora.get`, payload).then(response=>{
        	this.preguntas = response.data.preguntas
        	this.bitacora  = response.data.bitacora
					this.dialogBitacora = true 
        }).catch( error =>{
        	this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })

			}
    },
  }

</script>
<style>
  .v-data-table__wrapper{

  }

  .v-data-table__wrapper::-webkit-scrollbar {
    -webkit-appearance: none !important;
  }

  .v-data-table--fixed-header > .v-data-table__wrapper::-webkit-scrollbar:vertical {
    width: 10px !important;
  }

  .v-data-table__wrapper::-webkit-scrollbar:vertical{
    width: 10px !important;
  }

  .v-data-table__wrapper::-webkit-scrollbar-button:increment,.v-data-table__wrapper::-webkit-scrollbar-button{
    display: none;
  }

  .v-data-table__wrapper::-webkit-scrollbar:horizontal{
    height: 10px !important;
  }

  .v-data-table__wrapper::-webkit-scrollbar-thumb{
    background-color: #BABCBF;
    border-radius: 20px;
    border: 1px solid #ededed;
  }

  #yellow{
    background-color: yellow !important;
  }


</style>